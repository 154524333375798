import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { push } from 'connected-react-router';
import { URLS } from 'bv360/utils/urls';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { searchPractices, getPractices } from 'bv360/redux/actions/user';
import 'react-json-pretty/themes/monikai.css';

import SearchForm from './SearchForm';
import * as Yup from 'yup';
import ReactTable from 'react-table-6';

import OfficesSubtable from './offices-subtable';
import EditPracticeModal from './EditPracticeModal';

const requiredMessage = 'Required';
const searchValidation = Yup.object().shape({
  practice: Yup.object().shape({
    search: Yup.string()
      .required(requiredMessage)
      .nullable()
  })
});

function createDescribeColumn(bv360_admin = false) {
  const dispatch = useDispatch();
  const superUserNPICColumn = ({ original }) => {
    if (original.npi_edited) {
      return `${original.npi_edited} / ${original.mdm_id}`;
    } else {
      return `${original.npi ? original.npi : 'Not Available'} / ${original.mdm_id}`;
    }
  };

  const nonSuperUserNPICColumn = ({ original }) => {
    return original.npi_edited ? original.npi_edited : original.npi;
  };
  const columns = [
    {
      Header: 'Practice (Click to Manage)',
      id: 'name',
      accessor: ({ name, name_edited }) => [name, name_edited],
      Cell: ({ original }) => original.name_edited || original.name
    },
    {
      Header: 'NPI',
      id: 'npi',
      accessor: ({ npi, npi_edited }) => [npi, npi_edited],
      Cell: bv360_admin ? superUserNPICColumn : nonSuperUserNPICColumn
    },
    {
      Header: 'Group',
      id: 'organization_name',
      Cell: ({ original }) => original.organization_name
    },
    {
      Header: 'Personnel',
      id: 'personnel',
      Cell: row => {
        return (
          <button
            onClick={() => {
              dispatch(getPractices({ name: row.original.name, id: row.original.id }));
              dispatch(push(URLS.PRACTICE_USER_MANAGEMENT));
            }}
            className="btn btn-primary"
          >
            {`Users: ${row.original?.people?.users || 0} Physicians: ${row.original?.people
              ?.physicians || 0}`}
          </button>
        );
      }
    }
  ];
  return columns;
}

// `${original.npi_edited} / ${original.mdm_id}` || `${original.npi} / ${original.mdm_id}`
const padding = { marginLeft: '15px', marginRight: '15px', marginBottom: '15px' };

export default function() {
  const dispatch = useDispatch();
  const { loading, error, admin_practice_names } = useSelector(state => state.user);
  const { is_customer_service } = useSelector(state => state.user.user);
  const { is_superuser } = useSelector(state => state.user.user);
  const bv360_admin = () => {
    return is_superuser || is_customer_service;
  };

  const [editPracticeModal, setEditPracticeModal] = useState(false);
  const editPracticeModalToggle = () => setEditPracticeModal(!editPracticeModal);

  return (
    <Container style={{ paddingTop: '120px', paddingBottom: '8rem', maxWidth: '80vw' }}>
      <Row>
        <Formik
          initialValues={{
            practice: {
              showWithRemovedDate: false,
              practiceOrGroup: 'practices',
              search: ''
            }
          }}
          onSubmit={values => dispatch(searchPractices(values))}
          validationSchema={searchValidation}
          validateOnChange={true}
        >
          {formikProps => <SearchForm {...formikProps} loading={loading} error={error} />}
        </Formik>
      </Row>

      <ReactTable
        data={admin_practice_names}
        columns={createDescribeColumn(bv360_admin)}
        className="-striped -highlight"
        showPagination={true}
        SubComponent={({ original }) => (
          <div style={padding}>
            <h4>Offices</h4>
            <OfficesSubtable offices={original.offices} />
          </div>
        )}
        getTdProps={(s, rowInfo, column, i) => ({
          onClick: (e, handleOriginal) => {
            if (rowInfo && ['npi', 'name'].includes(column.id)) {
              dispatch(getPractices({ name: rowInfo.original.name, id: rowInfo.original.id }));
              editPracticeModalToggle();
            }

            if (handleOriginal) {
              handleOriginal();
            }
          },
          style: {
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center'
          }
        })}
      />

      <EditPracticeModal show={editPracticeModal} onHide={editPracticeModalToggle} />
    </Container>
  );
}
