import React from 'react';
import ReactTable from 'react-table-6';
import { phoneFormatter } from 'shared/utils/formatting';

const columns = [
  {
    Header: 'Office Name',
    id: 'office_name',
    minWidth: 200,
    Cell: ({ original }) => original.office_name
  },
  {
    Header: 'Street',
    id: 'street',
    minWidth: 200,
    accessor: ({ address1, address1_edited }) => [address1, address1_edited],
    Cell: ({ original }) => original.address1_edited || original.address1
  },
  {
    Header: 'Suite',
    id: 'suite',
    accessor: ({ address2, address2_edited }) => [address2, address2_edited],
    Cell: ({ original }) => original.address2_edited || original.address2
  },
  {
    Header: 'City',
    id: 'City',
    accessor: ({ city, city_edited }) => [city, city_edited],
    Cell: ({ original }) => original.city_edited || original.city
  },
  {
    Header: 'State',
    id: 'State',
    accessor: ({ state, state_edited }) => [state, state_edited],
    Cell: ({ original }) => original.state_edited || original.state
  },
  {
    Header: 'Zip',
    id: 'zip',
    accessor: ({ zip, zip_edited }) => [zip, zip_edited],
    Cell: ({ original }) => original.zip_edited || original.zip
  },
  {
    Header: 'Phone',
    id: 'suite',
    accessor: ({ main_telephone, main_telephone_edited }) => [
      main_telephone,
      main_telephone_edited
    ],
    Cell: ({ original }) =>
      original.main_telephone_edited
        ? phoneFormatter(original.main_telephone_edited)
        : phoneFormatter(original.main_telephone)
  },
  {
    Header: 'Fax',
    id: 'suite',
    accessor: ({ fax, fax_edited }) => [fax, fax_edited],
    Cell: ({ original }) =>
      original.fax_edited ? phoneFormatter(original.fax_edited) : phoneFormatter(original.fax)
  }
];

export default function({ offices }) {
  if (!offices || offices.length === 0) return 'No offices in practice';
  return (
    <ReactTable
      data={offices}
      columns={columns}
      className="-striped -highlight"
      showPagination={false}
      minRows={1}
    />
  );
}
