import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

const chunkFailedMessage = /Loading chunk [\d]+ failed/;

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state.error && nextState.error === false);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (error?.message && chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error) {
      this.setState({ error: false });
      return (
        <div
          className="snap"
          onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
        >
          <h3>
            <i className="fa fa-fw fa-exclamation-triangle" /> Something went wrong.
          </h3>
          <p>Our team has been notified, but click here fill out a report.</p>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
