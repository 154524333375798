import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import PracticeDetails from 'bv360/components/practice-details/PracticeDetails';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { URLS } from 'bv360/utils/urls';
import Spinner from 'shared/components/spinner/spinner';

class EditPracticeModal extends Component {
  componentDidMount() {

  }
  render() {

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: 12}}>
            <Button onClick={() => this.props.actions.push(URLS.PRACTICE_USER_MANAGEMENT)} style={{ margin: '10px'}}>
              Click to Manage Practice Personnel
            </Button>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.loading ? <Spinner isStatic /> : <PracticeDetails modal />}
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(
  state => ({
    loading: state.user.loading
  }),
  dispatch => ({
    actions: {
      push: bindActionCreators(push, dispatch)
    }
  })
)(EditPracticeModal);
