import { USER_LOGIN_NEEDED } from 'bv360/redux/actions/user';

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => next => action => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    const { promise, types, type, emit, message, ...rest } = action; // eslint-disable-line no-redeclare

    if (emit) {
      socket.emit(emit, message);

      if (!client.socket[emit]) {
        socket.on(emit, data => {
          dispatch({ type, result: data });
        });
        client.socket[emit] = true;
      }

      return true;
    }

    if (!promise) {
      return next(action);
    }

    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ ...rest, type: REQUEST });

    const actionPromise = promise(client);

    actionPromise
      .then(
        ({ body }) => {
          const result = body;
          next({ ...rest, result, type: SUCCESS });
        },
        error => {
          // Django middleware letting us know we need to login
          if (error.status === 401 && error.header['login-required'] === 'true') {
            localStorage.removeItem('user_id');
            next({ ...rest, error, type: USER_LOGIN_NEEDED });
          } else if (
            error.status === 401 &&
            error.header &&
            error.header.hasOwnProperty('user-change') &&
            error.header['user-change'] === 'true'
          ) {
            window.location.replace('/mybv360/app');
          }
          next({ ...rest, error, type: FAILURE });
        }
      )
      .catch(error => {

        next({ ...rest, error, type: FAILURE });
      });

    return actionPromise;
  };
}
