import React, { lazy, Suspense, useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import Spinner from 'shared/components/spinner/spinner';
import Layout from 'bv360/components/app/layout';
import { URLS } from 'bv360/utils/urls';
import Container from 'react-bootstrap/Container';

import ReferenceRequest from 'bv360/components/reference-request/ReferenceRequest';
import RequestSample from './components/request-sample/RequestSample';
import Header from 'bv360/components/app/header';
import Footer from 'bv360/components/app/footer';

const Dashboard = lazy(() => import('bv360/components/dashboard/'));
const SessionNotice = lazy(() => import('bv360/components/notice/session-expired'));
const AccountSetupNotice = lazy(() => import('bv360/components/notice/account-setup'));
const CorevistDown = lazy(() => import('bv360/components/notice/corevist-down'));
const Registration = lazy(() => import('shared/components/registration/registration'));
const RegistrationSuccess = lazy(() => import('shared/components/registration/success'));
const AdminContacted = lazy(() => import('shared/components/registration/admin-contacted'));
const LoginPage = lazy(() => import('bv360/components/app/login'));
const QuickSign = lazy(() => import('bv360/components/exogen-rx/quick-sign/'));
const QuickStandaloneSign = lazy(() => import('bv360/components/exogen-rx/quick-sign/standalone'));

const QuickSignSuccess = lazy(() => import('bv360/components/exogen-rx/quick-sign/success'));
const QuickSignDownloadSuccess = lazy(() =>
  import('bv360/components/exogen-rx/quick-sign/download-success')
);
const QuickSignEsignSuccess = lazy(() =>
  import('bv360/components/exogen-rx/quick-sign/esign-success')
);

const AccountProfile = lazy(() => import('bv360/components/account-profile/AccountProfile'));

const PracticeDetails = lazy(() => import('bv360/components/practice-details/PracticeDetails'));

const PracticeUserManagement = lazy(() =>
  import('bv360/components/practice-user-management/PracticeUserManagement')
);

const PracticeSearch = lazy(() =>
  import('bv360/components/sysadmin-practice-management/PracticeSearch')
);
const ConfirmPracticeUserPage = lazy(() => import('bv360/components/practice-user-management/confirm-practice-user'));

const PrivateRoute = ({ component: Component, store, ...rest }) => {
  const userState = store.getState().user;

  const isSystemsAdmin = (userState?.user && userState?.user?.is_superuser) || false;
  const isBV360CustomerService =
    userState?.user?.groups?.includes('BV360 Customer Service') || false;

  return (
    <Route
      {...rest}
      render={props =>
        userState && userState.user ? (
          <Component
            {...props}
            isSystemsAdmin={isSystemsAdmin}
            isBV360CustomerService={isBV360CustomerService}
          />
        ) : (
          <Redirect to={URLS.LOGIN} />
        )
      }
    />
  );
};

const NotFoundPage = () => {
  return (
    <Container className="content--app">
      <h1>Doh! 404!</h1>
      <p>We couldn't find the page you are looking for!</p>
    </Container>
  );
};

export default ({ history, store }) => {
  return (
    <ConnectedRouter history={history}>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path={URLS.SSO} exact component={props => <Redirect to={URLS.LOGIN} />} />
          <Route path={URLS.LOGIN} exact component={LoginPage} />

          <Route path={URLS.QUICK_SIGN_STANDALONE} exact component={QuickStandaloneSign} />
          <Route path={URLS.QUICK_SIGN} exact component={QuickSign} />
          <Route path={URLS.QUICK_SIGN_SUCCESS} exact component={QuickSignSuccess} />

          <Route
            path={URLS.QUICK_SIGN_DOWNLOAD_SUCCESS}
            exact
            component={QuickSignDownloadSuccess}
          />
          <Route path={URLS.QUICK_SIGN_ESIGN_SUCCESS} exact component={QuickSignEsignSuccess} />

          <Route
            path={URLS.REGISTRATION}
            exact
            component={props => {
              return (
                <>
                  <Header externalPage={true} />
                  <Registration {...props} />
                  <Footer />
                </>
              );
            }}
          />
          <Route
            path={URLS.REGISTRATION_SUCCESS}
            exact
            component={props => (
              <>
                <Header externalPage={true} />
                <RegistrationSuccess {...props} />
                <Footer />
              </>
            )}
          />

          <Route
            path={URLS.ADMIN_CONTACTED}
            exact
            component={props => (
              <>
                <Header externalPage={true} />
                <AdminContacted {...props} />
                <Footer />
              </>
            )}
          />

          <PrivateRoute
            path={URLS.BASE}
            component={props => {
              return (
                <Layout {...props}>
                  <Suspense fallback={<Spinner />}>
                    <Switch>
                      <Route path={URLS.CONFIRM_PRACTICE_USER} exact component={ConfirmPracticeUserPage} />
                      <Route path={URLS.BASE} exact component={Dashboard} />

                      <Route
                        path={URLS.DOCUMENT_LIBRARY}
                        exact
                        render={props => (
                          <ReferenceRequest {...props} key={Math.floor(Math.random() * 100)} />
                        )}
                      />

                      <Route path={URLS.REQUEST_SAMPLE} exact component={RequestSample} />

                      <Route path={URLS.ACCOUNT_PROFILE} exact component={AccountProfile} />

                      <Route path={URLS.SETUP_NOTICE} exact component={AccountSetupNotice} />
                      <Route path={URLS.COREVIST_DOWN} exact component={CorevistDown} />
                      <Route path={URLS.SESSION_NOTICE} exact component={SessionNotice} />

                      <Route
                        path={URLS.PRACTICE_USER_MANAGEMENT}
                        exact
                        component={PracticeUserManagement}
                      />
                      <Route
                        path={URLS.PRACTICE_DETAIL_MANAGEMENT}
                        exact
                        component={PracticeDetails}
                      />

                      {(props.isBV360CustomerService || props.isSystemsAdmin) && (
                        <Route
                          path={URLS.PRACTICE_ADMINISTRATION}
                          exact
                          component={PracticeSearch}
                        />
                      )}

                      <Route component={NotFoundPage} status={404} />
                    </Switch>
                  </Suspense>
                </Layout>
              );
            }}
            store={store}
          />

          <Route component={NotFoundPage} status={404} />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  );
};
