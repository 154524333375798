import { REHYDRATE } from 'redux-persist/lib/constants';
import { push } from 'connected-react-router';
import { URLS } from 'bv360/utils/urls';
import createReducer from 'shared/utils/createReducer';
import apiClient from 'bv360/utils/api-client';
import getApiError from 'shared/utils/error';
import config from 'bv360/config';

import { getCurrentUser } from 'bv360/redux/actions/user';

const COPILOT_WARNING_ACCEPT = 'COPILOT_WARNING_ACCEPT';
const COPILOT_WARNING_ACCEPT_SUCCESS = 'COPILOT_WARNING_ACCEPT_SUCCESS';
const COPILOT_WARNING_ACCEPT_FAIL = 'COPILOT_WARNING_ACCEPT_FAIL';

const CLEAR_ERROR = 'DASHBOARD/CLEAR_ERROR';
const REGISTER_NEW_COPILOT = 'DASHBOARD/REGISTER_NEW_COPILOT';
const REGISTER_NEW_COPILOT_SUCCESS = 'DASHBOARD/REGISTER_NEW_COPILOT_SUCCESS';
const REGISTER_NEW_COPILOT_FAIL = 'DASHBOARD/REGISTER_NEW_COPILOT_FAIL';

const REGISTER_NEW_COREVIST = 'DASHBOARD/REGISTER_NEW_COREVIST';
const REGISTER_NEW_COREVIST_SUCCESS = 'DASHBOARD/REGISTER_NEW_COREVIST_SUCCESS';
const REGISTER_NEW_COREVIST_FAIL = 'DASHBOARD/REGISTER_NEW_COREVIST_FAIL';

const VERIFY_COREVIST = 'DASHBOARD/VERIFY_COREVIST';
const VERIFY_COREVIST_SUCCESS = 'DASHBOARD/VERIFY_COREVIST_SUCCESS';
const VERIFY_COREVIST_FAIL = 'DASHBOARD/VERIFY_COREVIST_FAIL';

const COREVIST_ACCOUNT_STATUS = 'DASHBOARD/COREVIST_ACCOUNT_STATUS';
const COREVIST_ACCOUNT_STATUS_SUCCESS = 'DASHBOARD/COREVIST_ACCOUNT_STATUS_SUCCESS';
const COREVIST_ACCOUNT_STATUS_FAIL = 'DASHBOARD/COREVIST_ACCOUNT_STATUS_FAIL';

const SYSTEM_CONFIG = 'SYSTEM_CONFIG';
const SYSTEM_CONFIG_SUCCESS = 'SYSTEM_CONFIG_SUCCESS';
const SYSTEM_CONFIG_FAIL = 'SYSTEM_CONFIG_FAIL';

const TOGGLE_VERIFY_MODAL = 'DASHBOARD/TOGGLE_VERIFY_MODAL';
const TOGGLE_COPILOT_MODAL = 'DASHBOARD/TOGGLE_COPILOT_MODAL';
const TOGGLE_COREVIST_MODAL = 'DASHBOARD/TOGGLE_COREVIST_MODAL';
const TOGGLE_COREVIST_ACCCOUNT_MODAL = 'DASHBOARD/TOGGLE_COREVIST_ACCCOUNT_MODAL';
const TOGGLE_THANKS_MODAL = 'DASHBOARD/TOGGLE_THANKS_MODAL';

const TOGGLE_WARNING_MODAL = 'TOGGLE_WARNING_MODAL';

const initialState = {
  bypassCopilotFax: false,
  copilotContext: false,
  loading: false,
  error: null,
  invalidInvoiceCustomer: false,
  showCopilotRegisterModal: false,
  showCorevistRegisterModal: false,
  showCorevistAccountStatusModal: false,
  showThanksModal: false,
  showWarningModal: false,
  showVerifyCorevistModal: false,
  systemConfig: {}
};

export default createReducer(initialState, {
  [REHYDRATE]: state => {
    return {
      ...state
    };
  },
  [CLEAR_ERROR]: state => ({ ...state, error: null }),
  [COPILOT_WARNING_ACCEPT]: state => {
    return {
      ...state,
      loading: true
    };
  },
  [COPILOT_WARNING_ACCEPT_SUCCESS]: state => {
    return {
      ...state,
      loading: false
    };
  },
  [COPILOT_WARNING_ACCEPT_FAIL]: state => {
    return {
      ...state,
      loading: false
    };
  },
  [COREVIST_ACCOUNT_STATUS]: (state, action) => {
    return {
      ...state,
      loading: true,
      showCorevistAccountStatusModal: true
    };
  },
  [COREVIST_ACCOUNT_STATUS_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      showCorevistAccountStatusModal: false
    };
  },
  [COREVIST_ACCOUNT_STATUS_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false
    };
  },
  [REGISTER_NEW_COREVIST]: (state, action) => {
    return {
      ...state,
      loading: true,
      error: null
    };
  },
  [REGISTER_NEW_COREVIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      showVerifyCorevistModal: false,
      showThanksModal: true
    };
  },
  [REGISTER_NEW_COREVIST_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  },
  [REGISTER_NEW_COPILOT]: (state, action) => {
    return {
      ...state,
      loading: true,
      error: null
    };
  },
  [REGISTER_NEW_COPILOT_SUCCESS]: (state, { showThanksModal = true, showWarningModal = false }) => {
    return {
      ...state,
      loading: false,
      showCopilotRegisterModal: false,
      showWarningModal,
      showThanksModal,
      copilotContext: showThanksModal ? true : false
    };
  },
  [REGISTER_NEW_COPILOT_FAIL]: (state, { error, bypassFax }) => {
    return {
      ...state,
      loading: false,
      error: error,
      bypassCopilotFax: bypassFax
    };
  },
  [SYSTEM_CONFIG_SUCCESS]: (state, action) => {
    return {
      ...state,
      systemConfig: action.result
    };
  },
  [TOGGLE_COPILOT_MODAL]: (state, action) => {
    return {
      ...state,
      showCopilotRegisterModal: action.show,
      bypassCopilotFax: action.bypassFax
    };
  },
  [TOGGLE_COREVIST_ACCCOUNT_MODAL]: (state, action) => {
    return {
      ...state,
      showCorevistAccountStatusModal: action.show
    };
  },
  [TOGGLE_COREVIST_MODAL]: (state, action) => {
    return {
      ...state,
      error: null,
      showCorevistRegisterModal: action.show
    };
  },
  [TOGGLE_THANKS_MODAL]: (state, action) => {
    return {
      ...state,
      copilotContext: false,
      showThanksModal: action.show
    };
  },
  [TOGGLE_VERIFY_MODAL]: (state, action) => {
    return {
      ...state,
      error: null,
      showVerifyCorevistModal: action.show
    };
  },
  [TOGGLE_WARNING_MODAL]: state => ({ ...state, showWarningModal: !state.showWarningModal }),
  [VERIFY_COREVIST]: state => {
    return {
      ...state,
      invalidInvoiceCustomer: false,
      loading: true,
      error: null
    };
  },
  [VERIFY_COREVIST_SUCCESS]: state => {
    return {
      ...state,
      loading: false,
      showVerifyCorevistModal: false,
      showThanksModal: true
    };
  },
  [VERIFY_COREVIST_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      invalidInvoiceCustomer: action.invalidInvoiceCustomer ? action.invalidInvoiceCustomer : false,
      error: action.error
    };
  }
});

const faxRequiredErrors = [
  'Existing user not in BV360 Reimbursement Solution. Please provide a fax number and resubmit.',
  "Cannot clone this user's permissions. Please provide a fax number and resubmit."
];
export function registerCopilotAccount(values) {
  return (dispatch, getState) => {
    dispatch({ type: REGISTER_NEW_COPILOT });
    apiClient
      .post('/mybv360/api/copilot/register', { params: values })
      .then(response => {
        dispatch(getCurrentUser());
        const result = response.body;

        if (result?.redirect) {
          const userState = getState().user;
          const url = userState?.user?.is_demo ? config.copilotDemoUrl : config.copilotOkaRedirect;
          window.open(url, '_blank');
          dispatch({ type: REGISTER_NEW_COPILOT_SUCCESS, result, showThanksModal: false });
        } else {
          dispatch({
            type: REGISTER_NEW_COPILOT_SUCCESS,
            result,
            showThanksModal: result?.copilotWarning ? false : true,
            showWarningModal: result?.copilotWarning
          });
        }
      })
      .catch(err => {
        const error = getApiError(err);
        const bypassFax = !faxRequiredErrors.includes(error);
        dispatch({ type: REGISTER_NEW_COPILOT_FAIL, error, bypassFax });
      });
  };
}

export function acceptCopilotWarning() {
  return {
    types: [COPILOT_WARNING_ACCEPT, COPILOT_WARNING_ACCEPT_SUCCESS, COPILOT_WARNING_ACCEPT_FAIL],
    promise: client => client.put(`mybv360/api/copilot/warning/`)
  };
}

export function registerNewAccount(values) {
  return dispatch => {
    dispatch({ type: REGISTER_NEW_COREVIST });
    apiClient
      .post('/mybv360/api/corevist/register', { params: values })
      .then(response => {
        const result = response.body;
        dispatch(getCurrentUser());
        dispatch({ type: REGISTER_NEW_COREVIST_SUCCESS, result });
      })
      .catch(err => {
        const error = getApiError(err);
        dispatch({ type: REGISTER_NEW_COREVIST_FAIL, error });
      });
  };
}

export function corevistAccountStatus() {
  return (dispatch, getState) => {
    const dashState = getState().dashboard;
    dispatch({ type: COREVIST_ACCOUNT_STATUS });
    apiClient
      .post('/mybv360/api/corevist/account-status')
      .then(response => {
        const result = response.body;
        if (dashState?.systemConfig?.corevistIsDown) {
          dispatch(push(URLS.COREVIST_DOWN));
        } else {
          window.open(config.corevistOkaRedirect, '_blank');
          dispatch(getCurrentUser());
          dispatch({ type: COREVIST_ACCOUNT_STATUS_SUCCESS, result });
        }
      })
      .catch(err => {
        const error = getApiError(err);
        dispatch({ type: COREVIST_ACCOUNT_STATUS_FAIL, error });
      });
  };
}

export function verifyRegisterExisting(values) {
  return dispatch => {
    dispatch({ type: VERIFY_COREVIST });
    apiClient
      .post('/mybv360/api/corevist/register-existing', { params: values })
      .then(response => {
        const result = response.body;
        dispatch(getCurrentUser());
        dispatch({ type: VERIFY_COREVIST_SUCCESS, result });
      })
      .catch(err => {
        const error = getApiError(err);
        const invalidInvoiceCustomer = err.status && err.status === 404;

        dispatch({
          type: VERIFY_COREVIST_FAIL,
          error: invalidInvoiceCustomer
            ? 'We couldn’t find that customer/invoice combination. Would you like to try again?'
            : error,
          invalidInvoiceCustomer
        });
      });
  };
}

export function toggleVerifyModal(show) {
  return {
    type: TOGGLE_VERIFY_MODAL,
    show
  };
}

export function toggleCorevistModal(show) {
  return {
    type: TOGGLE_COREVIST_MODAL,
    show
  };
}

export function toggleCorevistAccountModal(show) {
  return {
    type: TOGGLE_COREVIST_ACCCOUNT_MODAL,
    show
  };
}

export function toggleCopilotModal(show, bypassFax = false) {
  return {
    type: TOGGLE_COPILOT_MODAL,
    show,
    bypassFax
  };
}

export function toggleThanksModal(show) {
  return {
    type: TOGGLE_THANKS_MODAL,
    show
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}

export function toggleWarningModal() {
  return dispatch => {
    dispatch(getCurrentUser());
    dispatch({ type: TOGGLE_WARNING_MODAL });
  };
}

export function getSystemConfig() {
  return {
    types: [SYSTEM_CONFIG, SYSTEM_CONFIG_SUCCESS, SYSTEM_CONFIG_FAIL],
    promise: client => client.get(`/mybv360/api/config`)
  };
}
