import React from 'react';
import { Button, Card, Col, Alert } from 'react-bootstrap';
import { Form, Field } from 'formik';
import RadioGroupField from 'shared/components/form/fields/radio-group';
import TextField from 'shared/components/form/fields/text';

const radioOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

const practiceOrGroupRadio = [
  { label: 'Practices', value: 'practices' },
  { label: 'Groups', value: 'groups' }
];

const SearchForm = ({ isValid, errors, touched, loading, error }) => {
  return (
    <Form className="registration-form">
      <Card>
        <Card.Body>
          <div className="row">
            <Col xs={12} sm={6} style={{ paddingRight: '30px' }}>
              <Field
                name="practice.search"
                maxLength={64}
                label="Name, Zip, NPI, Email, MDM"
                component={TextField}
              />
            </Col>
            <Col xs={12} sm={3}>
              <Field
                name="practice.practiceOrGroup"
                label="Search"
                component={RadioGroupField}
                elems={practiceOrGroupRadio}
              />
            </Col>
            <Col xs={12} sm={3}>
              <Field
                name="practice.showWithRemovedDate"
                label="Show Practices With Removed Date: "
                component={RadioGroupField}
                elems={radioOptions}
              />
            </Col>
          </div>
          <div className="row">
            <Col>
              <Alert variant="danger" show={!!error}>
                <i className="fa fa-fw fa-exclamation-triangle" /> {error}
              </Alert>
            </Col>
          </div>
          <div className="row">
            <Col>
              <Button type="submit" disabled={!isValid || loading}>
                {loading ? (
                  <span>
                    <i className="fa fa-fw fa-circle-notch fa-spin" />
                  </span>
                ) : (
                  'Search'
                )}
              </Button>
            </Col>
          </div>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default SearchForm;
