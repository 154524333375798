import 'core-js';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import createStore, { history } from './redux/createStore';

import * as Sentry from '@sentry/browser';

import 'bv360/theme/bootstrap.scss';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import 'react-table-6/react-table.css';

import AppWrapper from './app-wrapper';

require('@fortawesome/fontawesome-free/js/all.js');

if (!__SERVER__ && typeof window.__data !== 'undefined') {
  window.__data = {};
}

const { store, persistor } = createStore();

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component store={store} history={history} persistor={persistor} />
    </AppContainer>,
    document.getElementById('content')
  );
};

if (__SENTRY__) {
  Sentry.init({
    dsn: 'https://01aee278cb874c498b20a8459a5240e5@o141497.ingest.sentry.io/854614',
    release: __GIT_REVISION_,
    tags: { releaseDisplay: __VERSION__ },
    environment: process.env.NODE_ENV
  });
}

if (module.hot) {
  // Reload components
  module.hot.accept('./app-wrapper', () => {
    const newApp = require('./app-wrapper').default;
    render(newApp);
  });
}

render(AppWrapper);

if (__DEVTOOLS__) {
  window.React = React;
}

window.onload = () => {
  // Normalize the various vendor prefixed versions of getUserMedia.
  navigator.getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia;
};
