import React from 'react';
import Container from 'react-bootstrap/Container';
import Panel from 'exogen/components/common/pages/panel';

export default function(props) {
  return (
    <Container className="content--app">
      <Panel hideHeader>
        <h1>
          <i className="fa fa-fw fa-check-circle has-success" /> Thank you!
        </h1>
        <p>
          To complete your verification process, please call BV360 Reimbursement Solution at{' '}
          <a href="tel:1-833-692-8360">1-833-692-8360</a> during office hours 9am – 7pm ET.{' '}
        </p>
      </Panel>
    </Container>
  );
}
