import { connectRouter } from 'connected-react-router';
import { persistCombineReducers } from 'redux-persist';
import app from 'bv360/redux/reducers/app';
import user from 'bv360/redux/reducers/user';
import dashboard from 'bv360/components/dashboard/reducer';
import documents from 'bv360/redux/reducers/documents';
import docusign from 'bv360/redux/reducers/docusign';
import orders from 'bv360/redux/reducers/orders';
import formData from 'bv360/redux/reducers/form-data';
import localForage from 'localforage';

const config = {
  key: 'bv360',
  storage: localForage,
  blacklist: ['form', 'router'],
  throttle: 500
};

const rootReducer = history =>
  persistCombineReducers(config, {
    app,
    dashboard,
    docusign,
    documents,
    formData,
    orders,
    router: connectRouter(history),
    user
  });

export default rootReducer;
