import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { URLS } from 'bv360/utils/urls';

const pStyles = {
  fontSize: '1.2rem'
};

export default function(props) {
  const query = queryString.parse(props?.location?.search);

  return (
    <Container className="content--app">
      <h3>Thank you for registering!</h3>
      <br />
      <p style={pStyles}>
        You have successfully registered and requested access
        {query && query?.practice && query?.npi
          ? ` to practice NPI ${query.npi}, ${query.practice}`
          : null}
        ! Practice administrators have received and will process your request to join the practice.{' '}
      </p>
      <p style={pStyles}>
        Need help?{' '}
        <a href="tel:833-692-8360" className="link js-custom">
          Call us: 1-833-MyBV360
        </a>
      </p>
      <p>
        <Link to={URLS.LOGIN} className="btn btn-primary btn--fw">
          Back
        </Link>
      </p>
    </Container>
  );
}
