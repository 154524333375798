import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import _set from 'lodash.set';
import { Field } from 'formik';
import RadioGroupField from 'shared/components/form/fields/radio-group';
import TextField from 'shared/components/form/fields/text';
import EmailVerifyField from 'shared/components/form/fields/email-verify';
import _isEqual from 'lodash.isequal';
import DemoAwareButton from 'bv360/components/demo/button';

const radioOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

export default class AccountProfile extends React.PureComponent {
  render() {
    const { error, handleSubmit, isValid, loading, values } = this.props;
    const valuesUpdated = !_isEqual(this.props.currentStoreValues, this.props.values);

    return (
      <form onSubmit={handleSubmit} className="registration-form">
        <Card>
          <Card.Header>Profile</Card.Header>
          <Card.Body>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="user.firstName"
                  label="First Name"
                  maxLength={64}
                  component={TextField}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="user.lastName"
                  label="Last Name"
                  maxLength={64}
                  component={TextField}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="user.email"
                  label="Username (also your email address)"
                  maxLength={64}
                  component={EmailVerifyField}
                  verifyUrl="/mybv360/api/verify-user/contact/"
                  placeholder="Email*"
                  disabled={true}
                />
              </Col>
            </Row>{' '}
            <br />
            <Row className="form-group">
              <Col xs={6} sm={3}>
                <Field
                  name="user.emailNotification"
                  label="Email Notification"
                  component={RadioGroupField}
                  elems={radioOptions}
                />
              </Col>

              <Col xs={6} sm={3}>
                <Field
                  name="user.smsNotification"
                  label="SMS Notification"
                  component={RadioGroupField}
                  elems={radioOptions}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row>
          <Col>
            <Alert variant="danger" show={!!error}>
              <i className="fa fa-fw fa-exclamation-triangle" /> {error}
            </Alert>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 2, offset: 10 }}>
            <DemoAwareButton
              demoAlert="This would update the profile"
              type="submit"
              disabled={!isValid || loading || !valuesUpdated}
            >
              {loading ? <i className="fa fa-fw fa-circle-notch fa-spin" /> : 'Update'}
            </DemoAwareButton>
          </Col>
        </Row>
      </form>
    );
  }
}
