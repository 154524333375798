import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import _set from 'lodash.set';
import { Field } from 'formik';
import AddressLookupField from 'shared/components/form/fields/address-lookup';
import TextField from 'shared/components/form/fields/text';
import PhoneField from 'shared/components/form/fields/phone';
import SelectField from 'shared/components/form/fields/select';
import IntegerField from 'shared/components/form/fields/integer-field';
import { States } from 'shared/utils/constants';
import _isEqual from 'lodash.isequal';

const _get = require('lodash.get');
const errorKeyMap = {};

const stateOptions = States.map(state => ({ value: state.id, label: state.name }));

export default class PracticeDetails extends React.PureComponent {
  isString = val => typeof val === 'string' || val instanceof String;

  errorItem = (key, error, idx) => {
    const errorLabel = _get(errorKeyMap, key);
    let label = errorLabel || key;

    // the label cannot be an object or react will bork
    if (typeof label === 'object' && label !== null) label = key;

    return (
      <>
        <i className={`fa fa-fw fa-exclamation-triangle has-error`} />
        {label} : {error}
      </>
    );
  };

  formatErrors = (errors, result, segment = null, outerIdx = 0) => {
    const errorKeys = Object.keys(errors);

    errorKeys.forEach((key, idx) => {
      if (this.isString(errors[key]) || Array.isArray(errors[key])) {
        const value = Array.isArray(errors[key]) ? errors[key].join(' ') : errors[key];
        const errorKey = segment ? `${segment}${key}` : key;

        if (key !== 'offices') {
          result.push(
            <div key={`${idx}-${outerIdx}`}>
              {this.errorItem(errorKey, value, result.length, segment)}
            </div>
          );
        }
      } else {
        this.formatErrors(errors[key], result, `${key}.`, outerIdx);
      }
    });
    return result;
  };

  render() {
    let practiceErrors = [];
    let officeErrors = [];

    if (this.props.errors?.practice) {
      practiceErrors = this.formatErrors(this.props.errors?.practice[0], []);
      if (this.props.errors.practice[0].offices) {
        officeErrors = this.props.errors.practice[0].offices.reduce((acc, curr, index) => {
          if (curr) {
            return acc.concat(this.formatErrors(curr, [], null, index));
          } else {
            return acc;
          }
        }, []);
      }
    }
    const { practice_edit_error, error, handleSubmit, isValid, loading, values } = this.props;
    console.log('values', values)
    const valuesUpdated = !_isEqual(this.props.currentStoreValues, this.props.values);

    return (
      <form onSubmit={handleSubmit} className="registration-form">
        {values.practice &&
          values.practice.map((practice, practice_index) => (
            <Card key={practice_index}>
              <Card.Header>
                <Row>
                  <Col xs={12} sm={6}>
                    <Field
                      name={`practice[${practice_index}].organization.name`}
                      label="Organization Name"
                      maxLength={64}
                      component={TextField}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Field
                      name={`practice[${practice_index}].organization.mdm_id`}
                      label="Organization MDM"
                      maxLength={10}
                      component={TextField}
                      disabled={true}
                    />
                  </Col>
                </Row>{' '}
                <br />
              </Card.Header>
              <Card.Header>
                <Row>
                  <Col xs={12} sm={6}>
                    <Field
                      name={`practice[${practice_index}].name`}
                      label="Practice Name"
                      maxLength={64}
                      component={TextField}
                      // disabled={true}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Field
                      name={`practice[${practice_index}].npi`}
                      label="Practice NPI"
                      maxLength={10}
                      component={TextField}
                      disabled={
                        !this.props.isSystemsAdmin &&
                        this.props.initialValues?.practice[practice_index]?.npi
                      }
                    />
                  </Col>
                </Row>{' '}
                <br />
                <Row>
                  <Col xs={12} sm={6}>
                    <Field
                      name={`practice[${practice_index}].tax_id`}
                      label="Tax ID"
                      maxLength={9}
                      component={TextField}
                      // disabled={true}
                    />
                  </Col>

                  {this.props.isSystemsAdmin && (
                    <Col xs={12} sm={6}>
                      <Field
                        name={`practice[${practice_index}].removed_date`}
                        label="Removed Date"
                        placeholder="N/A"
                        maxLength={64}
                        component={TextField}
                        disabled={true}
                      />
                    </Col>
                  )}
                </Row>
                <br />
                <Row>
                  <Col xs={12} sm={6}>
                    <Field
                      name={`practice[${practice_index}].alt_npis`}
                      label="Alternative NPIs"
                      maxLength={1000}
                      component={TextField}
                      note={'Enter values seperated by commas'}
                      // disabled={true}
                    />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {practice.offices &&
                  practice.offices.map((office, office_index) => (
                    <ListGroup key={office_index}>
                      <ListGroup.Item>
                        <Row>
                          <Col xs={12} sm={6}>
                            <Field
                              name={`practice[${practice_index}].offices[${office_index}].address1`}
                              aptField={`practice[${practice_index}].offices[${office_index}].address2`}
                              cityField={`practice[${practice_index}].offices[${office_index}].city`}
                              stateField={`practice[${practice_index}].offices[${office_index}].state`}
                              zipField={`practice[${practice_index}].offices[${office_index}].zip`}
                              // showValidation
                              maxLength={64}
                              label="Street*"
                              component={AddressLookupField}
                              disabled={!!values.practice[practice_index].offices[office_index].mdm_id}
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <Field
                              name={`practice[${practice_index}].offices[${office_index}].address2`}
                              maxLength={64}
                              label="Suite"
                              component={TextField}
                              disabled={!!values.practice[practice_index].offices[office_index].mdm_id}
                            />
                          </Col>
                        </Row>

                        <br />

                        <Row>
                          <Col xs={12} sm={6}>
                            <Field
                              name={`practice[${practice_index}].offices[${office_index}].city`}
                              maxLength={32}
                              label="City*"
                              component={TextField}
                              disabled={!!values.practice[practice_index].offices[office_index].mdm_id}
                            />
                          </Col>

                          <Col xs={12} sm={6}>
                            <Field
                              name={`practice[${practice_index}].offices[${office_index}].state`}
                              label="State*"
                              simpleValue
                              component={SelectField}
                              options={stateOptions}
                              disabled={!!values.practice[practice_index].offices[office_index].mdm_id}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs={12} sm={6}>
                            <Field
                              name={`practice[${practice_index}].offices[${office_index}].zip`}
                              maxLength={5}
                              label="Zip*"
                              component={TextField}
                              disabled={!!values.practice[practice_index].offices[office_index].mdm_id}
                            />
                          </Col>
                          {this.props.isSystemsAdmin && (
                            <Col xs={12} sm={6}>
                              <Field
                                name={`practice[${practice_index}].offices[${office_index}].mdm_id`}
                                maxLength={5}
                                label="MDM ID"
                                component={TextField}
                                disabled={true}
                              />
                            </Col>
                          )}
                          {/* <Col xs={12} sm={6}>
                            <Field
                              name={`practice[${practice_index}].offices[${office_index}].main_telephone`}
                              label="Phone Number*"
                              showValidation
                              component={PhoneField}
                            />
                          </Col> */}
                        </Row>
                        <br />
                        <Row>
                          <Col xs={12} sm={6}>
                            <Field
                              name={`practice[${practice_index}].offices[${office_index}].main_telephone`}
                              label="Phone Number*"
                              showValidation
                              component={PhoneField}
                              disabled={!!values.practice[practice_index].offices[office_index].mdm_id}
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <Field
                              name={`practice[${practice_index}].offices[${office_index}].main_telephone_extension`}
                              label="Phone Extension"
                              placeholder="12345"
                              showValidation
                              component={IntegerField}
                              maxLength={9}
                              disabled={!!values.practice[practice_index].offices[office_index].mdm_id}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs={12} sm={6}>
                            <Field
                              name={`practice[${practice_index}].offices[${office_index}].fax`}
                              label="Fax*"
                              showValidation
                              component={PhoneField}
                              disabled={!!values.practice[practice_index].offices[office_index].mdm_id}
                            />
                          </Col>

                          {this.props.isSystemsAdmin && (
                            <Col xs={12} sm={6}>
                              <Field
                                name={`practice[${practice_index}].removed_date`}
                                label="Removed Date"
                                placeholder="N/A"
                                maxLength={64}
                                component={TextField}
                                disabled={true}
                              />
                            </Col>
                          )}
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  ))}
                {/* <div className = "text-center" style={{margin: '10px'}}>
                  {!this.props.addOffice && <i className="fa fa-plus-circle fa-5x"
                    onClick={() => {
                      this.props.setAddOffice(true)
                      // this.setState({addOffice: true})
                    }}
                  style={{color: '#a864da'}}/> }

                </div> */}

                {this.props.addOffice && (
                  <ListGroup.Item>
                    <Row>
                      <Col xs={12} sm={6}>
                        <Field
                          name={`practice[${practice_index}].newOffice.address1`}
                          aptField={`practice[${practice_index}].newOffice.address2`}
                          cityField={`practice[${practice_index}].newOffice.city`}
                          stateField={`practice[${practice_index}].newOffice.state`}
                          zipField={`practice[${practice_index}].newOffice.zip`}
                          // showValidation
                          maxLength={64}
                          label="Street*"
                          component={AddressLookupField}
                          // disabled={!newOffice}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <Field
                          name={`practice[${practice_index}].newOffice.address2`}
                          maxLength={64}
                          label="Suite"
                          component={TextField}
                          // disabled={!newOffice}
                        />
                      </Col>
                    </Row>

                    <br />

                    <Row>
                      <Col xs={12} sm={6}>
                        <Field
                          name={`practice[${practice_index}].newOffice.city`}
                          maxLength={32}
                          label="City*"
                          component={TextField}
                          // disabled={true}
                        />
                      </Col>

                      <Col xs={12} sm={6}>
                        <Field
                          name={`practice[${practice_index}].newOffice.state`}
                          label="State*"
                          simpleValue
                          component={SelectField}
                          options={stateOptions}
                          //disabled={true}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={12} sm={6}>
                        <Field
                          name={`practice[${practice_index}].newOffice.zip`}
                          maxLength={5}
                          label="Zip*"
                          component={TextField}
                          //disabled={true}
                        />
                      </Col>
                      {this.props.isSystemsAdmin && (
                        <Col xs={12} sm={6}>
                          <Field
                            name={`practice[${practice_index}].newOffice.mdm_id`}
                            maxLength={5}
                            label="MDM ID"
                            component={TextField}
                            disabled={true}
                          />
                        </Col>
                      )}
                    </Row>
                    <br />
                    <Row>
                      <Col xs={12} sm={6}>
                        <Field
                          name={`practice[${practice_index}].newOffice.main_telephone`}
                          label="Phone Number*"
                          showValidation
                          component={PhoneField}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <Field
                          name={`practice[${practice_index}].newOffice.main_telephone_extension`}
                          label="Phone Extension"
                          placeholder="12345"
                          showValidation
                          component={TextField}
                          maxLength={9}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={12} sm={6}>
                        <Field
                          name={`practice[${practice_index}].newOffice.fax`}
                          label="Fax*"
                          showValidation
                          component={PhoneField}
                        />
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}
              </Card.Body>
            </Card>
          ))}

        <Row>
          <Col>
            <Alert variant="danger" show={!!error}>
              <i className="fa fa-fw fa-exclamation-triangle" /> {error}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert variant="danger" show={!!practice_edit_error}>
              <i className="fa fa-fw fa-exclamation-triangle" /> {practice_edit_error}
            </Alert>
          </Col>
        </Row>

        {/* <Row>
          <Col>
            <Alert variant="danger" show={!isValid }>
              <i className="fa fa-fw fa-exclamation-triangle" /> Missing or Invalid Form Field
            </Alert>
          </Col>
        </Row> */}

        {officeErrors.length !== 0 && (
          <Row>
            <Col>
              <Alert variant="danger">{officeErrors}</Alert>
            </Col>
          </Row>
        )}

        {practiceErrors.length !== 0 && (
          <Row>
            <Col>
              <Alert variant="danger">{practiceErrors}</Alert>
            </Col>
          </Row>
        )}

        <Row>
          <Col md={{ span: 2, offset: 10 }}>
            {/* <Button type="submit" disabled={!isValid || loading || !valuesUpdated}> */}
            <Button type="submit" disabled={loading || !valuesUpdated || !isValid}>
              {loading ? <i className="fa fa-fw fa-circle-notch fa-spin" /> : 'Update'}
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}
