import React from 'react';

export default function(props) {
  let memberPhone = null;
  let memberFax = null;
  if (props.phone) {
    memberPhone =
      ' ' + props.phone.substr(2, 3) + '-' + props.phone.substr(5, 3) + '-' + props.phone.substr(8);
  }
  if (props.fax) {
    memberFax =
      ' ' + props.fax.substr(2, 3) + '-' + props.fax.substr(5, 3) + '-' + props.fax.substr(8);
  }

  return (
    <div className="MemberInfo">
      {props.photo ? (
        <div className="Pic">
          <img src={'/media' + props.photo}></img>
        </div>
      ) : (
        <div className="Pic">
          <i className="fa fa-user fa-6x" ></i>{' '}
        </div>
      )}

      <div className="Detail">
        <div className="Membername">{props.fullName}</div>
        <div className="Designation">{props.title}</div>
        <div className="MemberProducts">{props.products}</div>
        <div className="ShortInfo">
          <p>
            <a href={`tel:${memberPhone}`}>Phone:{memberPhone}</a>
          </p>
          {!['BV360 Reimbursement Solution'].includes(props.fullName) && <p>Fax:{memberFax}</p>}
          {/*{['Customer Service', 'Customer Care'].includes(props.fullName) && <p>Fax:{memberFax}</p>}*/}
        </div>
      </div>
      <div className="clear"></div>
    </div>
  );
}

// style={{width: "130px", height: "130px", fontSize: "10em", textAlign: "Center"}}
