import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class WarningModal extends Component {
  componentDidMount() {
    this.props.onload();
  }
  render() {
    const { accept, close, show } = this.props;
    return (
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>BV360 Reimbursement Solution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="form-group">
            <Col>
              <p>
                When accessing BV360 Reimbursement Solution, if you don’t see a provider you need
                access to, please ask your Practice Administrator to add the provider to your
                practice. If you don’t see a location you need access to, please call BV360 Support
                at <a href="tel:8336928360">833-692-8360</a> and they will assist you in adding a
                new location.
              </p>
            </Col>
          </Row>

          <Row className="form-group">
            <Col md={12}>
              <div className="float-end">
                <Button className="btn btn-primary btn--fw" onClick={accept}>
                  <span>
                    <i className="fa fa-check fa-fw" />
                    Go
                  </span>
                </Button>{' '}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

WarningModal.propTypes = {
  accept: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default WarningModal;
