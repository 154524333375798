import React, { useState, useEffect } from 'react';
import Header from 'bv360/components/exogen-rx/app/header';
import Container from 'react-bootstrap/Container';
import Panel from 'exogen/components/common/pages/panel';
import ErrorBoundary from 'exogen/components/error/error';
import Card from 'react-bootstrap/Card';

const QuickSignDownloadSuccess = props => {

  console.log('made it here2')
  return (
    <>
      <Header externalPage={true} />
      <ErrorBoundary>
        <Container className="content--app">
          <Card>
            <Card.Body style={{fontSize: '1.5em'}}>
              <p>Thank you for using the Bioventus CMN process!</p>
              <br/>

              <p style={{marginBottom: '0px'}}>The CMN has downloaded. Please print the form, have the HCP sign and fax to <strong>866-486-2192.</strong> </p>
            </Card.Body>
          </Card>
        </Container>
      </ErrorBoundary>

    </>
  )
}

export default QuickSignDownloadSuccess;