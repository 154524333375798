import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getConfirmUserInfo, setConfirmUserInfo, toggleSetConfirmUserResultModal } from 'bv360/redux/actions/user';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Panel from 'exogen/components/common/pages/panel';
import { Navigate } from 'react-router-dom';
import { URLS } from 'bv360/utils/urls';
import Modal from 'react-bootstrap/Modal';

export default (props) => {


  const userMatchReg = /\/confirm-practice-user\/(.*)/

  const dispatch = useDispatch();
  const [inStateUserEmail, setInStateUserEmail] = useState(null)
  const [inStateComponentRendering, setInStateComponentRendering] = useState(true)
  const [inStatePropsMatch, setInStatePropsMatch] = useState(false)

  const { confirmUserLoading, userToBeConfirmed, confirmUserError, confirmUserResultModalOpen } = useSelector(state => state.user)

  useEffect(() => {
    // const windowLocationMatch = window?.location?.href?.match(userMatchReg)
    const propsLocationMatch = props.location?.pathname?.match(userMatchReg)
    if (propsLocationMatch) {
      const [strMatch, userEmail, ...rest] = propsLocationMatch;
      setInStatePropsMatch(true)
      setInStateUserEmail(userEmail)
      getConfirmationProcess({ userEmail: userEmail })
    }

    setInStateComponentRendering(false)
  }, [props.location?.pathname])

  const getConfirmationProcess = ({ userEmail = null }) => {
    // console.log('getting user data', userEmail)

    dispatch(getConfirmUserInfo({ userEmail: userEmail }))
  }

  const handleConfirm = () => {
    dispatch(setConfirmUserInfo({ userEmail: inStateUserEmail }))
    // success modal or set text to checkmark then change back to home page
    // window.location.replace(URLS.BASE)
  }

  const handleDeny = () => {
    window.location.replace(URLS.BASE)
  }
  return (
    <>
      <Container style={{ paddingTop: '120px', paddingBottom: '8rem', display: 'flex', justifyContent: 'center' }} fluid={false}>
        <Panel
          title="Confirm User Access"
          loading={
            confirmUserLoading || inStateComponentRendering
          }
          headerSpinner={false}
          bodySpinner={true}
          cardStyle={{ minHeight: '400px', minWidth: "500px" }}
          headerStyle={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          headerInfoHoverExists={true}
          headerInfoText="This user is requesting access to your practice, please select from the options below. If you do not recognize this user, please close this window and inform your Bioventus representative."
          // spinnerStyle={{ marginTop: '200px' }}
          hoverPlacement={"bottom"}
        >
          {((confirmUserError && !confirmUserResultModalOpen) || !inStatePropsMatch) ?
            <Row className="confirm-requesting-user" style={{ marginTop: '125px' }}>
              User access confirmation unavailable.
            </Row>
            :
            <>
              <Row className="confirm-requesting-user">
                <ListGroup variant="flush" style={{ fontSize: 'large' }}>
                  <ListGroup.Item> First Name: {userToBeConfirmed?.first_name || ""}</ListGroup.Item>
                  <ListGroup.Item> Last Name: {userToBeConfirmed?.last_name || ""}</ListGroup.Item>
                  <ListGroup.Item> Email: {userToBeConfirmed?.email || ""}</ListGroup.Item>
                </ListGroup>
              </Row>

              <Row style={{ justifyContent: 'space-evenly', marginTop: '50px' }}>
                <Button size="lg" onClick={e => handleConfirm()}>
                  Confirm
                </Button>
                <Button size="lg" onClick={(e) => handleDeny()}>
                  Deny
                </Button>
              </Row>
            </>
          }
        </Panel>
      </Container>
      <Modal
        show={confirmUserResultModalOpen}
        onHide={() => {
          window.location.replace(URLS.BASE)
          dispatch(toggleSetConfirmUserResultModal(false))}
        }
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          {confirmUserError ? "Confirm user failure" : "Success"}
        </Modal.Header>
      </Modal>
    </>
  )
}
