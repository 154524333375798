import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class ThanksModal extends Component {
  render() {
    const { accept, copilot, show } = this.props;

    return (
      <Modal show={show} onHide={accept}>
        <Modal.Header closeButton>
          <Modal.Title>Thank you!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="form-group">
            <Col>
              {copilot ? (
                <span>
                  <ul>
                    <li>
                      Your request for enrollment has been sent to the BV360 Reimbursement Solution
                      team.
                    </li>{' '}
                    <li>
                      Due to PHI concerns, the BV360 Reimbursement Solution team will need to speak
                      to you via phone to validate your enrollment.
                    </li>{' '}
                    <li>
                      <strong>
                        To complete your verification process, please call BV360 Reimbursement
                        Solution at <a href="tel:1-833-692-8360">1-833-692-8360</a> during office
                        hours 9am – 7pm ET.
                      </strong>{' '}
                      Once your account has been activated, you will receive an email from
                      no-reply@cmcopilot.com. You may then log back in to the MyBV360 Portal and
                      click on the BV360 Reimbursement Solution tile to access the system.
                    </li>
                  </ul>
                </span>
              ) : (
                <p>
                  Thank you for submitting a request for registration for the MyBV360 Injectables
                  Orders & Payment tile. If you have completed the form in its totality you will be
                  set up immediately, if incomplete, expect an email from our verification team
                  within 24 hours for additional information to complete the process (this excludes
                  nights, weekend and holidays).
                </p>
              )}
            </Col>
          </Row>

          <Row className="form-group">
            <Col md={12}>
              <div className="float-end">
                <Button className="btn btn-primary btn--fw" onClick={accept}>
                  <span>
                    <i className="fa fa-check fa-fw" />
                    Close
                  </span>
                </Button>{' '}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

ThanksModal.propTypes = {
  accept: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default ThanksModal;
