import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import _set from 'lodash.set';
import _isEqual from 'lodash.isequal';
import _isEmpty from 'lodash.isempty';
import ListGroup from 'react-bootstrap/ListGroup';
import VirtualizedSelect from 'react-virtualized-select';

export default function userInfoModal(props) {
  const { loading, loadingCoPilot, copilotDetails, copilotDetailsError } = props;

  const [selectedPractice, setSelectedPractice] = useState(null);
  const [practiceOptions, setPracticeOptions] = useState(null);
  const [sortedDoctors, setSortedDoctors] = useState(null);

  useEffect(() => {
    setPracticeOptions(
      copilotDetails?.get_practices?.map(val => {
        let obj = {};
        obj.label = val.name;
        obj.value = val.name;
        obj.id = val.id;
        obj.npi = val.npi;
        obj.tax_id = val.tax_id;
        obj.address = val.address;
        obj.phone_number = val.phone_number;
        obj.fax_number = val.fax_number;
        obj.global_doctors = val.global_doctors;
        return obj;
      })
    );

    if (copilotDetails.enrollment?.enrollment_doctors) {
      setSortedDoctors(
        copilotDetails.enrollment?.enrollment_doctors.sort((a, b) =>
          `${a.lastname.trim()} ${a.firstname.trim()}`.localeCompare(
            `${b.lastname.trim()} ${b.firstname.trim()}`
          )
        )
      );
    }
  }, [copilotDetails]);

  useEffect(() => {
    if (practiceOptions && practiceOptions.length > 0) setSelectedPractice(practiceOptions[0]);
  }, [practiceOptions]);

  useEffect(() => {
    if (selectedPractice?.global_doctors) {
      setSortedDoctors(
        selectedPractice?.global_doctors.sort((a, b) =>
          `${a.lastname.trim()} ${a.firstname.trim()}`.localeCompare(
            `${b.lastname.trim()} ${b.firstname.trim()}`
          )
        )
      );
    }
  }, [selectedPractice]);

  const OptionRender = ({ key, option, selectValue, style }) => {
    return (
      <div
        key={key}
        onClick={() => selectValue(option)}
        style={{ ...style, overflow: 'hidden', cursor: 'pointer', left: '10px' }}
      >
        {option.value}
      </div>
    );
  };

  return (
    <Modal
      // {...props}
      show={props.show}
      onHide={() => {
        setSelectedPractice(null);
        props.onHide();
      }}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">User Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Header>
            <strong>CoPilot Status:</strong>{' '}
            {loadingCoPilot ? (
              <span>
                <i className="fa fa-fw fa-circle-o-notch fa-spin" />
              </span>
            ) : _isEmpty(copilotDetails) ? (
              'User Not Found'
            ) : copilotDetails.enrollment ? (
              copilotDetails.enrollment.id ? (
                `Enrolled, ID ${copilotDetails.enrollment.id}`
              ) : (
                'Enrolled'
              )
            ) : (
              'Active'
            )}
          </Card.Header>
          {!_isEmpty(copilotDetails) && (
            <ListGroup variant="flush">
              <ListGroup.Item>
                <strong>Name: </strong> {copilotDetails.firstname}
                {'  '}
                {copilotDetails.lastname}
                {'  '}
                <strong>Email: </strong> {copilotDetails.email}
              </ListGroup.Item>

              {copilotDetails.global_doctor && (
                <ListGroup.Item>
                  <strong>Doctor Name: </strong> {copilotDetails.global_doctor.firstname}
                  {'  '}
                  {copilotDetails.global_doctor.lastname}
                  {'  '}
                  <strong>Doctor NPI: </strong> {copilotDetails.global_doctor.npi}
                </ListGroup.Item>
              )}

              {copilotDetails.enrollment?.enrollment_practice && (
                <ListGroup.Item>
                  <strong>Enrollment Information</strong> <br />
                  <strong>Street: </strong> {copilotDetails.enrollment.enrollment_practice.street}
                  {'  '}
                  <strong>Suite: </strong> {copilotDetails.enrollment.enrollment_practice.suite}
                  {'  '}
                  <strong>City: </strong> {copilotDetails.enrollment.enrollment_practice.city}
                  {'  '}
                  <strong>State: </strong> {copilotDetails.enrollment.enrollment_practice.state}
                  {'  '}
                  <strong>Zip: </strong> {copilotDetails.enrollment.enrollment_practice.zip}
                  {'  '}
                  <strong>NPI: </strong> {copilotDetails.enrollment.enrollment_practice.npi}
                  {'  '}
                  <strong>Phone: </strong>{' '}
                  {copilotDetails.enrollment.enrollment_practice.phone_number}
                  {'  '}
                  <strong>Fax: </strong> {copilotDetails.enrollment.enrollment_practice.fax_number}
                </ListGroup.Item>
              )}

              {copilotDetails.enrollment?.enrollment_doctors && sortedDoctors && (
                <ListGroup.Item>
                  <strong>Enrollment Doctor(s)</strong>{' '}
                  <ul style={{ listStyleType: 'none' }}>
                    {sortedDoctors.map(val => {
                      return (
                        <div key={Math.random()}>
                          <li style={{ float: 'left', width: '251px' }}>
                            <strong>Name: </strong>
                            {val.firstname} {val.lastname}{' '}
                          </li>{' '}
                          <li>
                            {' '}
                            <strong>NPI: </strong>
                            {val.npi}
                          </li>
                        </div>
                      );
                    })}
                  </ul>
                </ListGroup.Item>
              )}

              {copilotDetails.get_practices && (
                <ListGroup.Item>
                  Select Practice
                  <VirtualizedSelect
                    value={selectedPractice}
                    options={practiceOptions}
                    onChange={val => setSelectedPractice(val)}
                    optionRenderer={OptionRender}
                  />
                </ListGroup.Item>
              )}
              {selectedPractice?.value && (
                <ListGroup.Item>
                  <strong>Tax ID: </strong> {selectedPractice.tax_id}
                  {'  '}
                  <strong>NPI: </strong> {selectedPractice.npi}
                  {'  '}
                  <strong>Phone: </strong> {selectedPractice.phone_number?.number}
                  {'  '}
                  <strong>Fax: </strong> {selectedPractice.fax_number?.number}{' '}
                  <strong>CoPilot ID: </strong> {selectedPractice.id}
                </ListGroup.Item>
              )}
              {selectedPractice?.address && (
                <ListGroup.Item>
                  <strong>Street: </strong> {selectedPractice.address?.street}
                  {'  '}
                  <strong>Suite: </strong> {selectedPractice.address?.suite}
                  {'  '}
                  <strong>City: </strong> {selectedPractice.address?.city}
                  {'  '}
                  <strong>State: </strong> {selectedPractice.address?.state}
                  {'  '}
                  <strong>Zip: </strong> {selectedPractice.address?.zip}
                </ListGroup.Item>
              )}
              {selectedPractice?.global_doctors && sortedDoctors && (
                <ListGroup.Item>
                  <strong>Doctor(s)</strong>{' '}
                  <ul style={{ listStyleType: 'none' }}>
                    {sortedDoctors.map(val => {
                      return (
                        <div key={Math.random()} style={{ display: 'flex' }}>
                          <div style={{ flex: '1' }}>
                            <strong>Name: </strong>
                            {val.firstname} {val.lastname}
                          </div>
                          <div style={{ flex: '1' }}>
                            <strong>NPI: </strong> {val.npi}
                          </div>
                          <div style={{ flex: '1' }}>
                            <strong>CoPilot ID: </strong>
                            {val.id}
                          </div>
                          {/* <li style={{"float": "left", "width":"251px"}}><strong>Name: </strong>{val.firstname} {val.lastname} </li> 
                    <li> <strong>NPI: </strong> {val.npi} {'  '}<strong>CoPilot ID: </strong>{val.id}</li> */}
                        </div>
                      );
                    })}
                  </ul>
                </ListGroup.Item>
              )}
            </ListGroup>
          )}
        </Card>
      </Modal.Body>
    </Modal>
  );
}
