import * as Yup from 'yup';

const requiredMessage = 'Required';

export default Yup.object().shape({
  helpmenu: Yup.object().shape({
    type: Yup.string()
      .required(requiredMessage),
    product: Yup.string()
      .required(requiredMessage),
      text: Yup.string()
        .required(requiredMessage)
  })
});
