import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from 'bv360/redux/actions/user';
import apiClient from 'bv360/utils/api-client';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import superagent from 'superagent';
import { URLS } from 'bv360/utils/urls';
import Col from 'react-bootstrap/Col';
import Spinner from 'shared/components/spinner/spinner';
import Container from 'react-bootstrap/Container';

import SuccessModal from 'bv360/components/request-modals/SuccessModal';
import ConfirmationModal from 'bv360/components/request-modals/ConfirmationModal';

const scrollToSection = elem => {
  const node = document.getElementById(elem);
  node?.scrollIntoView(true);

  // now account for fixed header
  const scrolledY = window.scrollY;

  if (scrolledY) {
    window.scroll(0, scrolledY - 80);
  }
};

class ReferenceRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      selected_contact: {},
      selected_office: null,
      comments: [],
      modalOpen: false,
      loading: true
    };
  }

  removeDuplicates(myArr, prop) {
    if (myArr === null || myArr === undefined) return [];
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.loading && prevState.loading) {
      scrollToSection(`${this.props?.match?.params?.docType}-section`);
    }
  }

  async componentDidMount() {
    try {
      let document_list = await apiClient.get(`/mybv360/api/document-library`);

      if (document_list.body.error) {
        this.setState({ error: document_list.body.error });
      } else {
        if (document_list && document_list.body && document_list.body.data) {
          // sample_list = sample_list.body.data
          // filter so only durolane for now
          document_list = document_list.body.data.filter(val => val);
        }

        this.setState({ loading: false, documents: document_list });
      }
    } catch (e) {
      this.setState({ loading: false, error: e });
    }
  }

  sendEmail = async (offices = null, contacts = null) => {
    let selectedDocs = this.state.documents.filter(val => val.checked === true);
    selectedDocs.map(val => (val['item_name'] = val['document_name']));

    this.setState({ loading: true, error: null });
    try {
      const response = apiClient.post(`/mybv360/api/send-itemrequest-email`, {
        params: {
          office: this.state.selected_office ? this.state.selected_office : offices[0],
          items: selectedDocs,
          comments: this.state.comments.length === 0 ? 'None' : this.state.comments,

          // since the selector is closed turned off for now
          // contact: this.state.selected_contact.email ? this.state.selected_contact.email : contacts[0].email
          contact: contacts[0].email
        }
      });
      if (response.body.error) {
        this.setState({ error: response.body.error });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      this.setState({
        loading: false,
        error: e?.body?.error ? e.body.error : 'Error sending email'
      });
    }
  };

  confirmationModalToggle = () => {
    this.setState({
      confirmationModalOpen: !this.state.confirmationModalOpen
    });
  };

  successModalToggle = () => {
    this.setState({
      successModalOpen: !this.state.successModalOpen
    });
  };

  handleCheckChange = (index, event) => {
    let documents = [...this.state.documents]; // immutably set documents to this.state.documents
    let document = { ...documents[index], checked: event.target.checked, quantity: '1' }; // modify the one which was checked
    // note the quantity handler code is disabled so the default quantity of 1 will be used

    documents[index] = document;
    this.setState({ documents });
  };

  handleQuantityChange = (index, event) => {
    let documents = [...this.state.documents]; // immutably set documents to this.state.documents
    let document = { ...documents[index], quantity: event.target.value }; // modify the one which was checked

    documents[index] = document;
    this.setState({ documents });
  };

  handleOfficeChange = event => {
    this.setState({ selected_office: event.target.value });
  };

  handleCommentChange = event => {
    this.setState({ comments: event.target.value });
  };

  handleDeliverToChange = selectedContact => {
    this.setState({ selected_contact: selectedContact });
  };

  docSections = [
    { display: 'exogen', name: 'EXOGEN' },
    { display: 'ha-portfolio materials', name: 'HA Portfolio Materials' },
    { display: 'durolane', name: 'DUROLANE' },
    { display: 'gelsyn', name: 'GELSYN-3' },
    { display: 'supartz', name: 'SUPARTZ FX' }
  ];

  render() {
    const isSystemsAdmin = this.props.user?.groups?.is_superuser || false;
    const isBV360CustomerService =
      this.props.user?.groups?.includes('BV360 Customer Service') || false;

    if (this.state.loading || this.props.globalStateLoading) {
      return (
        <div style={{ paddingTop: '120px', paddingBottom: '8rem' }}>
          <Spinner isStatic={true} />
        </div>
      );
    }

    if (this.props.practices.length === 0) {
      if (isSystemsAdmin || isBV360CustomerService) {
        return (
          <Container style={{ paddingTop: '120px', paddingBottom: '8rem' }} fluid={false}>
            <h1>
              {' '}
              You are not currently associated with any practice. Please first search for and select
              a practice from 'Superuser Practice Administration'
            </h1>{' '}
          </Container>
        );
      } else {
        return (
          <Container style={{ paddingTop: '120px', paddingBottom: '8rem' }} fluid={false}>
            <h1> The current user is not associated with any practice.</h1>{' '}
          </Container>
        );
      }
    }
    const documents = this.state.documents;
    let selectedDocs = this.state.documents.filter(val => val.checked === true);

    let offices = [];
    offices = this.props.practices
      ?.reduce((acc, curr) => {
        return acc.concat(curr.offices);
      }, [])
      .filter(val => val != null) // this filters out both null and undefined
      .map(val => val.address1);

    let contacts = [];
    contacts = this.props.practices
      ?.reduce((acc, curr) => {
        return acc.concat(curr.offices);
      }, [])
      .filter(val => val.contacts != null) // this filters out both null and undefined
      .reduce((acc, curr) => {
        return acc.concat(curr.contacts);
      }, [])
      .filter(contact => contact.name !== 'Customer Care');

    if (contacts.length) {
      contacts = this.removeDuplicates(contacts, 'name');
    }

    let documents_for_product = [];

    return (
      <Container
        style={{ minHeight: '100%', height: '100%', paddingTop: '120px', paddingBottom: '8rem' }}
        fluid={false}
      >
        {' '}
        {/* maybe make the form width 70% or something to make the quantity box closer */}
        {offices ? (
          <>
            <Form>
              {this.docSections.map(({ name, display }) => {
                documents_for_product = documents.filter(val => val.product_name === name);
                documents_for_product.sort((a, b) => (a.document_name > b.document_name ? 1 : -1));

                return (
                  <div key={display}>
                    <Form.Group controlId={name} id={`${display}-section`}>
                      <Form.Label style={{ fontSize: 20, textTransform: 'uppercase' }}>
                        {name}
                      </Form.Label>
                      {documents_for_product.map(val => {
                        // find the index of the product in the original documents array
                        let document_names = documents.map(val => val.document_name);
                        let original_index = document_names.indexOf(val.document_name);

                        return (
                          <Row key={val.document_name}>
                            <Col>
                              <Form.Check
                                style={{ fontSize: 14 }}
                                type={'checkbox'}
                                id={val.document_name}
                                label={val.document_name}
                                onChange={e => this.handleCheckChange(original_index, e)}
                              />
                            </Col>
                            <Col>
                              {/* {val.checked && ( // quantity disabled, will default to 1
                                <Form.Control
                                  as="select"
                                  style={{ width: '100px' }}
                                  onChange={e => this.handleQuantityChange(original_index, e)}
                                >
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </Form.Control>
                              )} */}
                            </Col>
                          </Row>
                        );
                      })}
                    </Form.Group>
                  </div>
                );
              })}
              <Form.Group controlId="offices">
                <Form.Label style={{ fontSize: 20 }}>Deliver To: </Form.Label>
                <Form.Control
                  as="select"
                  style={{ width: '500px' }}
                  onChange={e => this.handleOfficeChange(e)}
                >
                  {offices
                    ? offices.map((val, index) => <option key={`${val}-${index}`}>{val}</option>)
                    : null}
                </Form.Control>
              </Form.Group>

              {/* <Form.Group controlId="contacts">
              <Form.Label style={{ fontSize: 20 }}>Delivered By: </Form.Label>
              <Form.Control as="select" style={{ width: "500px" }}
                onChange={e => {
                  const selectedContact = contacts.filter(val => val.name == e.target.value)
                  this.handleDeliverToChange(...selectedContact)}}>


                {contacts ?
                  contacts.map((val, index) => (
                    <option key={val.email}>{val.name}</option>
                  ))
                  : null
                }
              </Form.Control>
            </Form.Group> */}

              <Form.Group controlId="documentComments">
                <Form.Label style={{ fontSize: 20 }}>Comments (optional)</Form.Label>
                <Form.Control as="textarea" rows="4" onChange={e => this.handleCommentChange(e)} />
              </Form.Group>

              <Button
                variant="primary"
                onClick={event => {
                  this.confirmationModalToggle();
                }}
                style={{ fontSize: 18 }}
                disabled={selectedDocs.length > 0 ? false : true}
              >
                Request
              </Button>
            </Form>

            <SuccessModal
              show={this.state.successModalOpen}
              onHide={this.successModalToggle}
              modalSamples={selectedDocs}
              modalOffice={this.state.selected_office}
              allOffices={offices}
              requestType={'literature'}
            />

            <ConfirmationModal
              show={this.state.confirmationModalOpen}
              onHide={this.confirmationModalToggle}
              successModal={this.successModalToggle}
              sendEmail={this.sendEmail}
              offices={offices}
              contacts={contacts}
              requestType={'literature'}
            />
          </>
        ) : (
          <h1 style={{ fontSize: 20 }}>
            {' '}
            No offices found for user {this.props.user.firstName} {this.props.user.lastName}
          </h1>
        )}
      </Container>
    );
  }
}

export default connect(
  state => ({
    user: state.user.user,
    practices: state.user.practices,
    globalStateLoading: state.user.loading
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(UserActions, dispatch)
    }
  })
)(ReferenceRequest);
