import * as Yup from 'yup';

const numberReg = /^\d+$/;
const numberRegMessage = 'Can only contain integers';

const deaReg = /^\S{2}\d{7}$/;
const deaMessage = 'DEA numbers must contain 2 letters, 6 digits, and 1 check digit';
const requiredMessage = 'Required';

export default Yup.object().shape({
  user: Yup.object().shape({
    firstName: Yup.string()
      .required(requiredMessage)
      .nullable(),
    lastName: Yup.string()
      .required(requiredMessage)
      .nullable(),
    emailNotification: Yup.bool()
      .required(requiredMessage),
    smsNotification: Yup.bool()
      .required(requiredMessage)
    
  })
});
