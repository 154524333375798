import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from 'bv360/config';

export default function({ close, loading, show }) {
  const handleSupportClick = () => {
    close();
    const win = window?.open(
      `mailto:${config.bv360SupportEmail}?subject=Injectables Orders and Payment account access`,
      '_blank'
    );
    win?.focus();
  };
  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Injectables Orders and Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Row className="form-group">
            <Col>
              <i className="spinner fa fa-circle-notch fa-spin fa-lg" /> Checking the status of your
              account...
            </Col>
          </Row>
        ) : (
          <Row className="form-group">
            <Col>
              A Bioventus representative will be in touch in the next 24 to 48 hours to provide you
              with access.
            </Col>
          </Row>
        )}

        <Row className="form-group">
          <Col md={12}>
            <div className="float-end">
              {!loading && (
                <>
                  <Button className="btn btn-primary btn--fw" onClick={handleSupportClick}>
                    Support
                  </Button>{' '}
                  <Button className="btn btn-primary btn--fw" onClick={close}>
                    <span>
                      <i className="fa fa-check fa-fw" />
                      Close
                    </span>
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
