import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { URLS } from 'bv360/utils/urls';
import { upperCaseFirstLetter } from 'shared/utils/string';
import { logout } from 'bv360/redux/actions/user';
import ContactUsModal from './modals/contact-us/';
import ThirdPartyModals from 'bv360/components/dashboard/third-party-modals';
import config from 'bv360/config';

import {
  corevistAccountStatus,
  toggleCorevistModal,
  toggleCopilotModal,
  toggleWarningModal
} from 'bv360/components/dashboard/reducer';

const LeftLogo = require('static/images/myBV360logo_white.png');
const RightLogo = require('static/images/bioventus-right.png');

export default function NavbarComponent({
  pathname = null,
  demoable = false,
  loadingDemo = false,
  isDemo = false,
  externalPage = false,
  isSystemsAdmin = false,
  isBv360Admin = false,
  isBV360CustomerService = false,
  startDemo,
  stopDemo
}) {
  const [collapsed, setCollapsed] = useState(true);

  const user = useSelector(state => state.user && state.user.user);
  const corevistIsDown = useSelector(state => state.dashboard?.systemConfig?.corevistIsDown);

  const dispatch = useDispatch();

  const username = user
    ? `${upperCaseFirstLetter(user.firstName)} ${upperCaseFirstLetter(user.lastName)}`
    : null;

  const [contactUsShow, setContactUsShow] = useState(false);
  const handleContactUsClose = () => setContactUsShow(false);
  const handleContactUsShow = () => setContactUsShow(true);

  const handleCorevistLink = () => {
    if (!user.is_superuser && user?.is_rep && !user?.is_demo) {
      alert(
        'Customers will be able to access the BV360 Injectables Reimbursement Solution. Sales representatives should access BV360 territory data through Okta.'
      );
    } else if (user?.corevist_user) {
      if (user?.is_demo) {
        alert(
          'Demo Mode: Customers will be able to access the Injectables Orders and Payment system.'
        );
      } else {
        window.open(config.corevistOkaRedirect, '_blank');
      }
    } else if (user?.corevist_in_progress) {
      dispatch(corevistAccountStatus());
    } else {
      dispatch(toggleCorevistModal(true));
    }
  };

  const handleCopilotLink = () => {
    if (!user.is_superuser && user?.is_rep && !user?.is_demo) {
      alert(
        'Customers will be able to access the BV360 Injectables Reimbursement Solution. Sales representatives should access BV360 territory data through Okta.'
      );
    } else if (user?.copilot_user) {
      if (!user?.copilot_warning_accepted && user?.copilot_quick_enroll) {
        dispatch(toggleWarningModal());
      } else {
        const url = user?.is_demo ? config.copilotDemoUrl : config.copilotOkaRedirect;
        window.open(url, '_blank');
      }
    } else {
      dispatch(toggleCopilotModal(true, user.practice_has_copilot_user));
    }
  };

  useEffect(() => {
    // optional chaining is an attempt to not break ie11
    const nodeList = document?.querySelectorAll('a.dropdown-toggle');
    nodeList?.forEach?.(elem => {
      elem.addEventListener('click', e => {
        e?.stopPropagation();
        // Let's hide the open siblings
        const subMenus = document?.querySelectorAll('ul.dropdown-menu .show');
        const subMenu = elem?.nextElementSibling;

        // hiding siblings
        subMenus.forEach(menu => {
          if (menu !== subMenu) menu.classList.remove('show');
        });

        if (subMenu?.classList?.contains('show')) {
          subMenu.classList.remove('show');
          const menusToClose = subMenu.querySelectorAll('ul.dropdown-menu');
          menusToClose.forEach(menuToClose => {
            menuToClose?.classList?.remove?.('show');
          });
        } else {
          subMenu?.classList?.add('show');
        }
      });
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setCollapsed(false); // Only set collapsed to false for desktop sizes
      const elems = document?.querySelectorAll?.('#TopHead .show');
      for (let i = 0; i < elems.length; i++) {
        elems[i]?.classList?.remove('show');
      }
    } else {
      setCollapsed(true);

      const elems = document?.querySelectorAll?.('#TopHead .show');
      for (let i = 0; i < elems.length; i++) {
        elems[i]?.classList?.add('show');
      }
    }
  }, [pathname]);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const closeNavbar = () => setCollapsed(true);

  const classOne = collapsed ? 'collapse navbar-collapse' : 'navbar-collapse show';
  const classTwo = collapsed ? 'navbar-toggler collapsed' : 'navbar-toggler';

  return (
    <header id="TopHead" style={{ backgroundImage: 'url(/static/images/dot.png)' }}>
      <div className="clear" />

      <div className="Logo">
        <Link to={URLS.BASE}>
          <img src={LeftLogo} alt="myBV360" className="myBV360-Header-Logo" />
        </Link>
      </div>
      <div className="LogoRight">
        <a href="https://www.bioventusglobal.com/" target="_blank" rel="noopener noreferrer">
          <img src={RightLogo} alt="Bioventus Global" />
        </a>
      </div>
      {!externalPage && (
        <div id="TopNav">
          <nav className="navbar navbar-expand-lg mainmenu">
            <button
              className={classTwo}
              type="button"
              onClick={toggleNavbar}
              aria-controls="navbarSupportedContent"
              aria-expanded={!collapsed}
              aria-label="Toggle navigation"
              style={{ backgroundColor: 'transparent' }}
            >
              <span className="fa fa-bars" />
            </button>

            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="linedown dropdown">
                  <ul className="dropdown-menu">
                    <li onClick={closeNavbar}>
                      <Link to={URLS.ACCOUNT_PROFILE} style={{ whiteSpace: 'nowrap' }}>
                        Account Profile
                      </Link>
                    </li>
                    {isBv360Admin && (
                      <>
                        <li onClick={closeNavbar}>
                          <Link to={URLS.PRACTICE_USER_MANAGEMENT} style={{ whiteSpace: 'nowrap' }}>
                            Practice Users
                          </Link>
                        </li>
                        <li onClick={closeNavbar}>
                          <Link
                            to={URLS.PRACTICE_DETAIL_MANAGEMENT}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            Practice Details
                          </Link>
                        </li>
                      </>
                    )}
                    {(isSystemsAdmin || isBV360CustomerService) && (
                      <li onClick={closeNavbar}>
                        <Link to={URLS.PRACTICE_ADMINISTRATION} style={{ whiteSpace: 'nowrap' }}>
                          Superuser Practice Administration
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <ul className="navbar-nav mr-auto">
                  <li className="linedown dropdown">
                    <a
                      className="dropdown-toggle"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={closeNavbar}
                    >
                      myBV360<span></span>
                    </a>
                    <ul className="dropdown-menu">
                      <li onClick={closeNavbar}>
                        <Link to={URLS.ACCOUNT_PROFILE} style={{ whiteSpace: 'nowrap' }}>
                          Account Profile
                        </Link>
                      </li>
                      {isBv360Admin && (
                        <>
                          <li onClick={closeNavbar}>
                            <Link
                              to={URLS.PRACTICE_USER_MANAGEMENT}
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Practice Users
                            </Link>
                          </li>
                          <li onClick={closeNavbar}>
                            <Link
                              to={URLS.PRACTICE_DETAIL_MANAGEMENT}
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Practice Details
                            </Link>
                          </li>
                        </>
                      )}
                      {(isSystemsAdmin || isBV360CustomerService) && (
                        <li onClick={closeNavbar}>
                          <Link to={URLS.PRACTICE_ADMINISTRATION} style={{ whiteSpace: 'nowrap' }}>
                            Superuser Practice Administration
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>

                  <li className="linedown dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={closeNavbar}
                    >
                      Services<span></span>
                    </a>
                    <ul className="dropdown-menu">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          EXOGEN
                        </a>
                        <ul className="dropdown-menu">
                          <li onClick={closeNavbar}>
                            <a href="/exogen-rx/app" target="_blank">
                              EXOGEN Rx
                            </a>
                          </li>
                          <li onClick={closeNavbar}>
                            <Link
                              to="/mybv360/app/document-library/exogen"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Product Literature
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          DUROLANE
                        </a>
                        <ul className="dropdown-menu">
                          <li onClick={closeNavbar}>
                            <a
                              href={corevistIsDown ? URLS.COREVIST_DOWN : '#'}
                              onClick={corevistIsDown ? null : handleCorevistLink}
                            >
                              Ordering/Invoice Payment
                            </a>
                          </li>
                          <li onClick={closeNavbar}>
                            <a href="#" onClick={handleCopilotLink}>
                              BV360 Reimbursement Solution
                            </a>
                          </li>
                          <li onClick={closeNavbar}>
                            <a
                              href="https://www.oakneepainrelief.com/supartz_fx/hcp_resources/"
                              target="_blank"
                            >
                              Patient Direct Purchase Program
                            </a>
                          </li>
                          <li onClick={closeNavbar}>
                            <Link
                              to="/mybv360/app/document-library/durolane"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Product Literature
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          GELSYN-3
                        </a>
                        <ul className="dropdown-menu">
                          <li onClick={closeNavbar}>
                            <a
                              href={corevistIsDown ? URLS.COREVIST_DOWN : '#'}
                              onClick={corevistIsDown ? null : handleCorevistLink}
                            >
                              Ordering/Invoice Payment
                            </a>
                          </li>
                          <li onClick={closeNavbar}>
                            <a href="#" onClick={handleCopilotLink}>
                              BV360 Reimbursement Solution
                            </a>
                          </li>
                          <li onClick={closeNavbar}>
                            <a
                              href="https://www.oakneepainrelief.com/supartz_fx/hcp_resources/"
                              target="_blank"
                            >
                              Patient Direct Purchase Program
                            </a>
                          </li>
                          <li onClick={closeNavbar}>
                            <Link
                              to="/mybv360/app/document-library/gelsyn"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Product Literature
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          SUPARTZ FX
                        </a>
                        <ul className="dropdown-menu">
                          <li onClick={closeNavbar}>
                            <a
                              href={corevistIsDown ? URLS.COREVIST_DOWN : '#'}
                              onClick={corevistIsDown ? null : handleCorevistLink}
                            >
                              Ordering/Invoice Payment
                            </a>
                          </li>
                          <li onClick={closeNavbar}>
                            <a href="#" onClick={handleCopilotLink}>
                              BV360 Reimbursement Solution
                            </a>
                          </li>
                          <li onClick={closeNavbar}>
                            <a
                              href="https://www.oakneepainrelief.com/supartz_fx/hcp_resources/"
                              target="_blank"
                            >
                              Patient Direct Purchase Program
                            </a>
                          </li>
                          <li onClick={closeNavbar}>
                            <Link
                              to="/mybv360/app/document-library/supartz"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Product Literature
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li className="linedown dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={closeNavbar}
                    >
                      Product Information<span></span>
                    </a>
                    <ul className="dropdown-menu">
                      <li onClick={closeNavbar}>
                        <a
                          href="https://www.exogen.com/us/physician/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          EXOGEN
                        </a>
                      </li>
                      <li onClick={closeNavbar}>
                        <a
                          href="https://www.durolane.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          DUROLANE
                        </a>
                      </li>
                      <li onClick={closeNavbar}>
                        <a href="https://www.gelsyn3.com" target="_blank" rel="noopener noreferrer">
                          GELSYN-3
                        </a>
                      </li>
                      <li onClick={closeNavbar}>
                        <a
                          href="https://www.supartzfx.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          SUPARTZ FX
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="linedown">
                    <a
                      href="https://www.bioventusacademy.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={closeNavbar}
                    >
                      Bioventus Academy<span></span>
                    </a>
                  </li>

                  <li className="dropdown">
                    <a href="#" onClick={handleContactUsShow}>
                      Contact Us<span></span>
                    </a>
                  </li>
                </ul>

                <li className="linedown dropdown" id="TopNavRight">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {username} <i className="fa fa-angle-down sign-out-icon" />
                  </a>
                  <ul className="dropdown-menu">
                    {demoable && (
                      <li onClick={isDemo ? stopDemo : startDemo}>
                        <a href="#" onClick={evt => evt.preventDefault()}>
                          <i className="fa fa-user-circle" /> {isDemo ? 'Stop' : 'Start'} Demo
                        </a>
                      </li>
                    )}
                    <li onClick={() => dispatch(logout())}>
                      <a href="#" onClick={evt => evt.preventDefault()}>
                        <i className="fa fa-sign-out-alt" /> Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
          <ContactUsModal show={contactUsShow} onHide={handleContactUsClose} loading={false} />
        </div>
      )}

      {/* <div className="clear" /> */}
      <ThirdPartyModals />
    </header>
  );
}
