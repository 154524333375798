import React from 'react';
import { errorValues, inputClassNames, hasDisabledStatus } from './utils';

export default class IntegerField extends React.PureComponent {
  handleChange = event => {
    
    const { field, form } = this.props;
    const cleanValue = this.stripCharacters(event.target.value);

    form.setFieldValue(field.name, cleanValue);
  };

  stripCharacters = string => {
    const re = new RegExp(/[^0-9]/, 'g');
    return string.replace(re, '');
  };

  render() {
    const {
      label,
      min,
      type,
      placeholder,
      defaultValue,
      maxLength,
      disabled,
      field,
      form: { status }
    } = this.props;
    const { fieldTouched, fieldError, errorClass } = errorValues(this.props);
    const isDisabledStatus = hasDisabledStatus(status, field.name);
    const inputClasses = inputClassNames(this.props, 'form-control');

    return (
      <div className={errorClass}>
        <label>{label}</label>
        <div>
          <input
            {...field}
            defaultValue={defaultValue}
            disabled={disabled || isDisabledStatus}
            maxLength={maxLength}
            min={min}
            placeholder={placeholder ? placeholder : label}
            type={type}
            value={field.value || ''}
            className={inputClasses}
            onChange={this.handleChange}
          />
          {fieldTouched && fieldError && <span className="has-error">{fieldError}</span>}
        </div>
      </div>
    );
  }
}
