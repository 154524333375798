import React from 'react';
import Container from 'react-bootstrap/Container';
import Panel from 'exogen/components/common/pages/panel';

export default function() {
  return (
    <Container className="content--app">
      <Panel hideHeader>
        <h3>
          <i className="fa fa-fw fa-exclamation-triangle has-warning" /> Maintenance Warning
        </h3>
        <p>
          Injectables Online Orders and Payments is down for maintenance. Please try again later.
        </p>
      </Panel>
    </Container>
  );
}
