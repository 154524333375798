import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { URLS } from 'bv360/utils/urls';
import { connect, ReactReduxContext } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import OktaSignIn from '@okta/okta-signin-widget';
import config from 'bv360/config';
import PropTypes from 'prop-types';

import CookieConsent from 'react-cookie-consent';

import * as UserActions from 'exogen/redux/actions/user';
import Table from 'react-bootstrap/Table';

import '@okta/okta-signin-widget/css/okta-sign-in.min.css';

// import '@okta/okta-signin-widget/dist/css/okta-theme.css';

import 'bv360/theme/features/okta-theme.scss';
import './theme-overrides.css';

import PrivacyPolicyModal from './modals/privacy-policy';

const RightLogo = require('static/images/bioventus-right.png');

class OktaLoginPage extends Component {
  static contextType = ReactReduxContext;

  constructor(props) {
    super(props);
    this.signIn = new OktaSignIn({
      logo: '/static/images/myBV360logo_white.png',
      baseUrl: config.oktaBaseUrl,
      authParams: {
        responseType: 'id_token',
        display: 'page'
      },
      customButtons: [
        {
          title: 'Register',
          className: 'btn-customAuth',
          click: function() {
            // the registration page is limited to superusers right now
            window.location.href = URLS.REGISTRATION;
          }
        }
      ],
      helpLinks: {
        help: 'mailto:customerserviceusa@bioventusglobal.com?subject=MyBV360%20Login%20Help',
        custom: [
          {
            text: 'Call us: 1-833-MyBV360',
            href: 'tel:833-692-8360'
          }
        ]
      },
      i18n: {
        en: {
          // Labels
          forgotpassword: 'Reset or Forgot Your Password'
          // help: 'redPandasAreCool'
        }
      }
    });
    this.state = {
      cookieFullview: false,
      privacyModal: false
    };
  }

  handleCookieFullView = () => {
    this.setState({
      cookieFullview: !this.state.cookieFullview
    });
  };

  componentDidMount() {
    this.signIn.renderEl(
      {
        el: '#login-div'
      },
      function success(res) {
        // The user has started the password recovery flow, and is on the confirmation
        // screen letting them know that an email is on the way.
        if (res.status === 'FORGOT_PASSWORD_EMAIL_SENT') {
          // Any followup action you want to take
          return;
        }

        // The user has started the unlock account flow, and is on the confirmation
        // screen letting them know that an email is on the way.
        if (res.status === 'UNLOCK_ACCOUNT_EMAIL_SENT') {
          return;
        }

        if (res.status === 'SUCCESS') {
          res.session.setCookieAndRedirect(config.oktaRedirect);
        } else {
          alert('Login failed');
        }
      }
    );
  }
  // style={{color:"#ECF0F1", maxHeight: "350px", overflow:"auto"}}
  render() {
    const { actions } = this.props;

    const date = new Date();
    const year = date.getFullYear();

    const privacyModalToggle = () => this.setState({ privacyModal: !this.state.privacyModal });

    return (
      <>
        <section
          className="login-background"
          style={{ backgroundImage: 'url(/static/images/background.jpg)' }}
        >
          <div className="bv360-container container-login card">
            <div className="card-body help-block">
              <div id="login-div" />
              <br />

              <p className="login-legaltext">
                If you are a current user of BV360 Reimbursement Solution or Bioventus Online
                Ordering and Bill Pay Portal, please click "Reset or Forgot Your Password" to reset
                your password. You will not need to register a new account. <br />
                <br />
                The content of this site is owned or managed by Bioventus and is protected under
                applicable copyright and trademark laws. The ownership of the copyright on all
                content, including content contributed by users of the site is held by Bioventus,
                and its use is expressly forbidden except in the context of your representation of
                Bioventus as an agent for Bioventus products and in accordance with Bioventus'
                policies, procedures and instructions. You may not frame or link to the website or
                any part of it without our express permission. You may download content only for
                your commercial purposes in accordance with the terms of your relationship with
                Bioventus and only during the term of such relationship, but no modifications of the
                content is permitted without the express prior written permission of Bioventus.
                Logging in is deemed acceptance of these terms by you.{' '}
                <a
                  href="#"
                  onClick={privacyModalToggle}
                  style={{ color: 'white', textDecoration: 'underline' }}
                >
                  Full Privacy Policy
                </a>
                <br /> <br />
                Copyright {year} Bioventus LLC. All Rights reserved.
              </p>
            </div>
          </div>

          <div className="rightLogo-login">
            <a href="https://www.bioventusglobal.com/" target="_blank">
              <img src={RightLogo} alt="Bioventus Global" />
            </a>
          </div>
        </section>
        <CookieConsent
          location="bottom"
          buttonText="I Understand"
          cookieName="bv360cookieConsent"
          enableDeclineButton
          flipButtons
          declineButtonText="I decline"
          setDeclineCookie={false}
          style={{ background: '#2B373B', display: 'block' }}
          buttonStyle={{
            color: '#fff',
            fontSize: '13px',
            backgroundColor: '#734694',
            marginTop: '0px'
          }}
          expires={365}
        >
          <p>
            Our website uses cookies and other similar analytical files. A “cookie” is a file that
            is sent to your computer by a website and automatically saved on your computer by your
            web browser (e.g. “Microsoft Edge, Google Chrome”). Each time you request a page from
            the website, your web browser sends this cookie back to the website server.
          </p>

          <p onClick={this.handleCookieFullView}>
            {this.state.cookieFullview ? (
              <a style={{ cursor: 'pointer' }}>Show Less</a>
            ) : (
              <a style={{ cursor: 'pointer' }}>Show More</a>
            )}
          </p>

          {this.state.cookieFullview && (
            <>
              <p>We use cookies to:</p>

              <ul>
                <li>
                  Recognize and track which parts of our website you visit when you are using our
                  website; and{' '}
                </li>
                <li>Customize your experience of our website.</li>
              </ul>

              <p>
                We may also use the information collected from our use of cookies to estimate our
                audience size, analyze trends and general usage patterns of our website, optimize
                our website, and for other research and marketing purposes. This is statistical
                information and does not identify any individual.
              </p>
              <p>
                In certain circumstances we may also match cookies to personal information that you
                disclose to us or that we already hold. These circumstances include where you have
                registered and provided the personal information referred to in the “Collection of
                Personal Information” section above.
              </p>

              <p>
                If you wish, you can set your browser to notify you before you receive a cookie, so
                you have the chance to accept it and you can also set your browser to refuse to
                receive or send all cookies. The website www.allaboutcookies.org (run by the
                Interactive Marketing Bureau) contains step-by-step guidance on how cookies can be
                switched off by users. Please be aware that if you do not accept cookies our website
                may not function correctly or at all.
              </p>

              <p>
                By using our website without disabling cookies you are consenting to our use of
                cookies as described in this Policy. We use the following cookies on our website:
              </p>
              <Table striped bordered size="sm" className="cookie-Table">
                <thead>
                  <tr>
                    <th style={{ width: '20%' }}>Cookie Name</th>
                    <th>Cookie Type</th>
                    <th>Expiration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>bv360cookieConsent</td>
                    <td>
                      Stores the user's cookie consent state for the current domain. If consent is
                      declined, this message will appear for every log-in.
                    </td>
                    <td>1 year</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </CookieConsent>

        <PrivacyPolicyModal show={this.state.privacyModal} onHide={privacyModalToggle} />
      </>
    );
  }
}

OktaLoginPage.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object,
  error: PropTypes.string
};

export default connect(
  state => ({
    user: state.user.user
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(UserActions, dispatch)
    }
  })
)(OktaLoginPage);
