import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import ErrorBoundary from './error-boundary';

import Header from './header';
import Footer from './footer';

import { URLS } from 'bv360/utils/urls';
import config from 'bv360/config';
import * as UserActions from 'bv360/redux/actions/user';
import { Redirect } from 'react-router-dom';

class Layout extends Component {
  render() {
    const { children, actions, loadingDemo, user } = this.props;

    const { startDemo, stopDemo } = actions;

    const { appUser, bv360User } = this.props;

    const isBv360Admin = (bv360User && bv360User?.[0] && bv360User?.[0]?.is_admin) || false;

    const isSystemsAdmin = (appUser && appUser.groups && appUser.is_superuser) || false;
    const isBV360CustomerService = appUser?.groups?.includes('BV360 Customer Service') || false;

    if (!user) {
      return <Redirect to={URLS.LOGIN} />;
    }

    const isDemo = user.is_demo;
    const demoable = isDemo || user.is_superuser || user.is_rep || user.is_staff;

    return (
      <div className="app">
        <Helmet {...config.app.head} />
        <Header
          pathname={this.props?.location?.pathname}
          demoable={demoable}
          loadingDemo={loadingDemo}
          isDemo={isDemo}
          isSystemsAdmin={isSystemsAdmin}
          isBv360Admin={isBv360Admin}
          startDemo={startDemo}
          stopDemo={stopDemo}
          isBV360CustomerService={isBV360CustomerService}
        />

        <ErrorBoundary>{children}</ErrorBoundary>

        <Footer />
        <ToastContainer
          position="top-right"
          progressClassName="transparent-progress"
          type="default"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          theme="colored"
        />
      </div>
    );
  }
}

Layout.propTypes = {
  user: PropTypes.object,
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

export default connect(
  state => ({
    user: state.user.user,
    loadingDemo: state.user.loadingDemo,
    ssoComplete: state.user.ssoComplete,
    appUser: state.user.user,
    bv360User: state.user.practice_users
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(UserActions, dispatch)
    }
  })
)(Layout);
