import * as Yup from 'yup';
import npiValidate, { npiReg, npiRegMessage } from 'shared/utils/npi';

const numberReg = /^\d+$/;
const numberRegMessage = 'Can only contain integers';

const alphabetReg = /^[a-zA-Z-',. ]+$/;
const alphabetMessage = 'Invalid Character';

const deaReg = /^\S{2}\d{7}$/;
const deaMessage = 'DEA numbers must contain 2 letters, 6 digits, and 1 check digit';
const requiredMessage = 'Required';

export default Yup.object().shape({
  provider: Yup.object().shape({
    npi: Yup.string()
      .min(10, 'Must contain 10 numbers')
      .test('npi-test', 'NPI is invalid', npiValidate)
      .matches(npiReg, npiRegMessage),
    dea: Yup.string().matches(deaReg, deaMessage),
    firstName: Yup.string()
      .matches(alphabetReg, alphabetMessage)
      .required(requiredMessage)
      .nullable(),
    middleName: Yup.string()
      .matches(alphabetReg, alphabetMessage)
      .nullable(),
    lastName: Yup.string()
      .matches(alphabetReg, alphabetMessage)
      .required(requiredMessage)
      .nullable(),
    email: Yup.string().email('Invalid email'),
    practice: Yup.number().required(requiredMessage)
  })
});
