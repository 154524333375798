import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from 'bv360/redux/reducer';
import thunk from 'redux-thunk';
import createMiddleware from 'bv360/redux/middleware/clientMiddleware';
import createDebounce from 'redux-debounced';
import client from 'bv360/utils/api-client';
import { persistStore } from 'redux-persist';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
// import {createLogger} from 'redux-logger';
export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middleware = [
    thunk,
    routerMiddleware(history),
    createMiddleware(client),
    createDebounce(),
    createSentryMiddleware(Sentry, {
      actionTransformer: action =>
        action.result ? { ...action, result: 'Result removed' } : action,
      getUserContext: state => ({
        ...state.user.user,
        territories: 'Removed',
        reps: 'Removed'
      }),
      stateTransformer: state => ({
        ...state,
        formData: {},
        rules: {}
      })
    })
  ];
  const enhancer = composeEnhancers(applyMiddleware(...middleware));

  const rootReducer = createRootReducer(history);

  const store = createStore(
    rootReducer, // root reducer with router state
    preloadedState,
    enhancer
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducer', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  const persistor = persistStore(store);

  return { store, enhancer, persistor };
}
