import React, { useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'bv360/redux/actions/user';

import 'react-json-pretty/themes/monikai.css';

import AccountForm from './Form';
// import './styles.css';
import validationSchema from './ValidationSchema';

export default function({ exogenRx = false, bv360 = false }) {
  const dispatch = useDispatch();
  const { loading, error, user, practices } = useSelector(state => state.user);
  const reduxValues = {
    user: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      emailNotification: user.emailNotification,
      smsNotification: user.smsNotification
    }
  };

  return (
    <Container style={{ paddingTop: '120px', paddingBottom: '8rem' }}>
      <Row>
        <Formik
          initialValues={reduxValues}
          enableReinitialize={true}
          isInitialValid={true}
          onSubmit={values => {
            dispatch(updateUser(values));
          }}
          displayName={'AccountProfile'}
          validationSchema={validationSchema}
          // validate={validate}
          validateOnChange={true}
          render={props => (
            <AccountForm
              {...props}
              loading={loading}
              error={error}
              currentStoreValues={reduxValues}
            />
          )}
        />
      </Row>
    </Container>
  );
}
