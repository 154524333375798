import * as Yup from 'yup';
import npiValidate, { npiReg, npiRegMessage } from 'shared/utils/npi';

const numberReg = /^(\s*|\d+)$/;
const numberRegMessage = 'Can only contain integers';

const requiredMessage = 'Required';

export const practiceSchema = Yup.object().shape({
  practice: Yup.array().of(
    Yup.object().shape({
      tax_id: Yup.string()
        .nullable()
        .test('len', 'Must be 9 Integers', val => {
          if (val && val.length === 9) {
            return true;
          } else if (val && val.length !== 9) {
            return false;
          } else {
            return true;
          }
        }),
      npi: Yup.string()
        .min(10, 'Must contain 10 numbers')
        .nullable()
        .test('npi-test', 'NPI is invalid', npiValidate)
        .matches(npiReg, npiRegMessage),
      offices: Yup.array().of(
        Yup.object().shape({
          main_telephone: Yup.string()
            .min(10, 'Must contain 10 numbers')
            .matches(numberReg, 'Must contain 10 numbers')
            .nullable()
            .required(requiredMessage),
          fax: Yup.string()
            .min(10, 'Must contain 10 numbers')
            .matches(numberReg, 'Must contain 10 numbers')
            .nullable()
            .required(requiredMessage),
          address1: Yup.string().required(requiredMessage),
          address2: Yup.string().nullable(),
          city: Yup.string().required(requiredMessage),
          state: Yup.string().required(requiredMessage),
          zip: Yup.string()
            .matches(numberReg, numberRegMessage)
            .required(requiredMessage),
          main_telephone_extension: Yup.string()
            .matches(numberReg, numberRegMessage)
            .nullable()
        })
      )

    })

  )
});

export const practiceSchemaNewOffice = Yup.object().shape({
  practice: Yup.array().of(
    Yup.object().shape({
      tax_id: Yup.string()
        .nullable()
        .test('len', 'Must be 9 Integers', val => {
          if (val && val.length === 9) {
            return true;
          } else if (val && val.length !== 9) {
            return false;
          } else {
            return true;
          }
        }),
      npi: Yup.string()
        .min(10, 'Must contain 10 numbers')
        .nullable()
        .test('npi-test', 'NPI is invalid', npiValidate)
        .matches(npiReg, npiRegMessage),
      offices: Yup.array().of(
        Yup.object().shape({
          main_telephone: Yup.string()
            .min(10, 'Must contain 10 numbers')
            .matches(numberReg, 'Must contain 10 numbers')
            .nullable()
            .required(requiredMessage),
          fax: Yup.string()
            .min(10, 'Must contain 10 numbers')
            .matches(numberReg, 'Must contain 10 numbers')
            .nullable()
            .required(requiredMessage),
          address1: Yup.string().required(requiredMessage),
          address2: Yup.string().nullable(),
          city: Yup.string().required(requiredMessage),
          state: Yup.string().required(requiredMessage),
          zip: Yup.string()
            .matches(numberReg, numberRegMessage)
            .required(requiredMessage),
          main_telephone_extension: Yup.string()
            .matches(numberReg, numberRegMessage)
            .nullable()
        })
      ),
      newOffice: Yup.object().shape({

        main_telephone: Yup.string()
          .min(10, 'Must contain 10 numbers')
          .matches(numberReg, 'Must contain 10 numbers')
          .nullable()
          .required(requiredMessage),
        fax: Yup.string()
          .min(10, 'Must contain 10 numbers')
          .matches(numberReg, 'Must contain 10 numbers')
          .nullable()
          .required(requiredMessage),
        address1: Yup.string().required(requiredMessage),
        address2: Yup.string().nullable(),
        city: Yup.string().required(requiredMessage),
        state: Yup.string().required(requiredMessage),
        zip: Yup.string()
          .matches(numberReg, numberRegMessage)
          .required(requiredMessage),
        main_telephone_extension: Yup.string()
          .matches(numberReg, numberRegMessage)
          .nullable()
      })

    })

  )
});