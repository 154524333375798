import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';

import ErrorBoundary from 'exogen/components/error/error';

import PhysicianForm from './form';
import validationSchema from './validationSchema';

const initialValues = {
  provider: {
    physicianExists: false,
    inviteProvider: true
  }
};

class AddPhysicianModal extends PureComponent {
  render() {
    const { error, practices, getpracticeusers } = this.props;

    let practiceID = null;
    if (typeof practices[0] !== 'undefined') practiceID = practices[0].id;

    return (
      <ErrorBoundary>
        <Modal show={this.props.show} onHide={this.props.onHide} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add a Provider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ErrorBoundary>
              <Formik
                displayName={'AddPhysician'}
                onSubmit={() => {}}
                initialValues={{ provider: { ...initialValues.provider, practice: practiceID } }}
                isInitialValid={false}
                validationSchema={validationSchema}
                enableReinitialize={true}
                validateOnChange={true}
                validateOnBlur={true}
                render={props => (
                  <PhysicianForm
                    {...props}
                    closeModal={this.props.onHide}
                    error={error}
                    getpracticeusers={getpracticeusers}
                  />
                )}
              />
            </ErrorBoundary>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
      </ErrorBoundary>
    );
  }
}

AddPhysicianModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  // loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  show: PropTypes.bool.isRequired
};

export default AddPhysicianModal;
