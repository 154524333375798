import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import config from 'bv360/config';
import {
  clearError,
  acceptCopilotWarning,
  registerCopilotAccount,
  registerNewAccount,
  toggleCopilotModal,
  toggleCorevistAccountModal,
  toggleCorevistModal,
  toggleVerifyModal,
  toggleThanksModal,
  toggleWarningModal,
  verifyRegisterExisting
} from 'bv360/components/dashboard/reducer';

import CorevistAccountStatusModal from './corevist-account-modal';
import CorevistVerifyModal from './corevist-verify-modal';
import RegisterModal from './register-modal';
import ThanksModal from './thanks-modal';
import WarningModal from './copilot-warning-modal';

export default function() {
  const {
    bypassCopilotFax,
    error,
    invalidInvoiceCustomer,
    loading,
    copilotContext,
    showCopilotRegisterModal,
    showCorevistAccountStatusModal,
    showCorevistRegisterModal,
    showVerifyCorevistModal,
    showThanksModal,
    showWarningModal,
    copilotAccountType
  } = useSelector(state => ({
    ...state.dashboard,
    copilotQuickEnroll: state.user?.user?.copilot_quick_enroll,
    copilotWarningAccepted: state.user?.user?.copilot_warning_accepted
  }));

  const dispatch = useDispatch();
  const warningShown = () => {
    dispatch(acceptCopilotWarning());
  };

  const closeAndGoToCopilot = () => {
    dispatch(toggleWarningModal());
    const url = config.copilotOkaRedirect;
    window.open(url, '_blank');
  };

  return (
    <>
      {showCopilotRegisterModal && (
        <RegisterModal
          accept={vals => dispatch(registerCopilotAccount(vals))}
          error={error}
          bypassFax={bypassCopilotFax}
          show={showCopilotRegisterModal}
          loading={loading}
          decline={() => dispatch(toggleCopilotModal(false))}
        />
      )}
      {showCorevistRegisterModal && (
        <RegisterModal
          accept={() => {
            dispatch(toggleCorevistModal(false));
            dispatch(toggleVerifyModal(true));
          }}
          corevist
          show={showCorevistRegisterModal}
          decline={() => dispatch(toggleCorevistModal(false))}
        />
      )}
      {showThanksModal && (
        <ThanksModal
          copilot={copilotContext}
          copilotAccountType={copilotAccountType}
          show={showThanksModal}
          accept={() => dispatch(toggleThanksModal(false))}
        />
      )}
      {showWarningModal && (
        <WarningModal
          accept={closeAndGoToCopilot}
          close={() => dispatch(toggleWarningModal())}
          onload={warningShown}
          show={showWarningModal}
        />
      )}
      {showVerifyCorevistModal && (
        <CorevistVerifyModal
          clearError={() => dispatch(clearError())}
          error={error}
          invalidInvoiceCustomer={invalidInvoiceCustomer}
          loading={loading}
          registerNew={(values) => dispatch(registerNewAccount(values))}
          verifyRegisterExisting={values => dispatch(verifyRegisterExisting(values))}
          show={showVerifyCorevistModal}
          decline={() => dispatch(toggleVerifyModal(false))}
        />
      )}
      {showCorevistAccountStatusModal && (
        <CorevistAccountStatusModal
          loading={loading}
          show={showCorevistAccountStatusModal}
          close={() => dispatch(toggleCorevistAccountModal(false))}
        />
      )}
    </>
  );
}
