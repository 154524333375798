import React from 'react';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Panel from 'exogen/components/common/pages/panel';
import config from 'bv360/config';
export default function() {
  const user = useSelector(state => state.user && state.user.user);

  const handleCopilotLink = evt => {
    evt.preventDefault();
    if (!user.is_superuser && user?.is_rep && !user?.is_demo) {
      alert(
        'Customers will be able to access the BV360 Injectables Reimbursement Solution. Sales representatives should access BV360 territory data through Okta.'
      );
    } else if (user?.copilot_user) {
      const url = user?.is_demo ? config.copilotDemoUrl : config.copilotOkaRedirect;
      window.open(url, '_blank');
    } else {
      alert('Error: you are not registered with BV360 Injectables Reimbursement Solutions');
    }
  };

  return (
    <Container className="content--app">
      <Panel hideHeader>
        <h3>
          <i className="fa fa-fw fa-lock has-warning" />
          Reimbursement Session Expired
        </h3>
        <p>
          For HIPAA privacy and security reasons your session in the BV360 Injectables Reimbursement
          Solutions section of the myBV360 portal has expired.
        </p>
        <p>To re-authenticate your session, please click the button below:</p>
        <p>
          <Button onClick={handleCopilotLink}>
            <i className="fa fa-unlock-alt" /> Extend Your Session
          </Button>
        </p>
      </Panel>
    </Container>
  );
}
