import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';

import ErrorBoundary from 'exogen/components/error/error';

import ContactUsForm from './form';
import validationSchema from './validation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from 'bv360/redux/actions/user';

class ContactUsModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // customer care contacts to be injected if getting them from backend doesn't work
      customerCareContacts: [
        {
          name: 'Customer Care',
          prefix: '',
          email: 'customerserviceusa@bioventus.com',
          phone: '+18008364080',
          fax: '+18668327284',
          photo: '/contact_photos/customerserviceusabioventusglobal.com.jpg'
        },
        {
          name: 'BV360 Reimbursement Solution',
          prefix: 'Benefits Investigations',
          email: 'bv360-support@cmcopilot.com',
          phone: '+18336928360',
          fax: null,
          photo: '/contact_photos/bv360-supportcmcopilot.com.jpg'
        }
      ]
    };
  }
  removeDuplicates(myArr, prop) {
    if (myArr === null || myArr === undefined) return [];

    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  render() {
    let contacts = [];
    let offices = [];
    let memberIndex;

    offices = this.props.practices
      ?.reduce((acc, curr) => {
        return acc.concat(curr.offices);
      }, [])
      .filter(val => val != null); // this filters out both null and undefined

    contacts = this.removeDuplicates(
      offices
        ?.filter(val => val.contacts != null) // this filters out both null and undefined
        .reduce((acc, curr) => {
          return acc.concat(curr.contacts);
        }, []),
      'id'
    );

    if (!offices || offices?.length === 0) contacts = this.state.customerCareContacts;
    if (
      !(
        contacts.map(val => val.name).includes('Customer Care') ||
        contacts.map(val => val.name).includes('Customer Service')
      )
    )
      contacts.push(this.state.customerCareContacts[0]);
    if (!contacts.map(val => val.name).includes('BV360 Reimbursement Solution'))
      contacts.push(this.state.customerCareContacts[1]);

    memberIndex = contacts.findIndex(val => val.name === 'Customer Care');
    if (memberIndex !== -1) contacts.push(contacts.splice(memberIndex, 1)[0]);
    memberIndex = contacts.findIndex(val => val.name === 'Customer Service');
    if (memberIndex !== -1) contacts.push(contacts.splice(memberIndex, 1)[0]);
    memberIndex = contacts.findIndex(val => val.name === 'BV360 Reimbursement Solution');
    if (memberIndex !== -1) contacts.push(contacts.splice(memberIndex, 1)[0]);

    return (
      <ErrorBoundary>
        <Modal show={this.props.show} onHide={this.props.onHide} centered>
          <Modal.Header closeButton>
            <Modal.Title>Contact Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ErrorBoundary>
              <Formik
                displayName={'ContactUs'}
                onSubmit={(values, actions) => {
                  this.props.actions.sendContactUsEmail(
                    {
                      body: values.helpmenu.text,
                      problem: values.helpmenu.type,
                      product: values.helpmenu.product,
                      contact: values.helpmenu.responder ? values.helpmenu.responder.value : null,
                      casenum: values.helpmenu.casenum ? values.helpmenu.casenum : null
                    },
                    this.props.onHide
                  );
                }}
                initialValues={{}}
                isInitialValid={false}
                validationSchema={validationSchema}
                enableReinitialize={true}
                validateOnChange={true}
                validateOnBlur={true}
                render={props => (
                  <ContactUsForm
                    {...props} // send all the formik props
                    // passing the close modal function down as props to the form
                    // do everything in the onSubmit instead?
                    closeModal={this.props.onHide}
                    contacts={contacts}
                    loading={this.props.loading}
                    error={this.props.error}
                    // getpracticeusers={getpracticeusers}
                  />
                )}
              />
            </ErrorBoundary>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
      </ErrorBoundary>
    );
  }
}

ContactUsModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  // loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  show: PropTypes.bool.isRequired
};

export default connect(
  state => ({
    loading: state.user.loading,
    error: state.user.error,
    practices: state.user.practices
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(UserActions, dispatch)
    }
  })
)(ContactUsModal);
