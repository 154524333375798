import React from 'react';
import { Field } from 'formik';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import apiClient from 'exogen-rx/utils/api-client';

import EmailVerifyField from 'shared/components/form/fields/email-verify';
import TextField from 'shared/components/form/fields/text';
import PhoneField from 'shared/components/form/fields/phone';
import NpiField from 'shared/components/form/fields/npi';
import isEmpty from 'lodash.isempty';
import _set from 'lodash.set';
import DemoAwareButton from 'bv360/components/demo/button';

class AddPhysicianForm extends React.PureComponent {
  state = {
    loading: false,
    error: null
  };

  addPhysician = async () => {
    this.setState({ loading: true, error: null });
    const { values, closeModal, getpracticeusers } = this.props;

    try {
      const response = await apiClient.post('mybv360/api/physicians', {
        params: { ...values.provider }
      });
      if (response.body.error) {
        this.setState({ error: response.body.error });
      } else {
        this.setState({ loading: false });
        // get the practice users again after you've added a user
        getpracticeusers();
        closeModal();
      }
    } catch (e) {
      this.setState({
        loading: false,
        error: e?.body?.error ? e.body.error : 'Error Adding Physician'
      });
    }
  };

  updateOrIgnore = (field, value) => {
    if (!value) return;
    this.props.setFieldValue(field, value, true);
    const touched = _set(this.props.touched, field, true);
    this.props.setTouched(touched);
  };

  providerNpiUpdate = ({
    firstName,
    middleName,
    lastName,
    fax,
    phone,
    physicianExists,
    specialty
  }) => {
    this.updateOrIgnore('provider.firstName', firstName);
    this.updateOrIgnore('provider.middleName', middleName);
    this.updateOrIgnore('provider.lastName', lastName);
    this.updateOrIgnore('provider.physicianExists', physicianExists || (firstName && lastName));
    this.updateOrIgnore('provider.specialty', specialty);
  };

  componentDidMount() {
    this.props.setStatus({ provider: { email: undefined } });
  }

  componentDidUpdate(prevProps) {
    if (this.props.values.provider.email !== prevProps.values.provider.email) {
      if (isEmpty(this.props.values.provider.email)) {
        this.props.setFieldValue('provider.inviteProvider', false);
      } else {
        this.props.setFieldValue('provider.inviteProvider', true);
      }
    }
  }

  render() {
    const { values, isValid } = this.props;
    const { error, loading } = this.state;

    const { physicianExists } = values.provider;

    return (
      <form>
        <Row className="form-group">
          <Col xs={12} sm={6}>
            <Field
              name="provider.npi"
              label="Provider NPI*"
              populateCallback={this.providerNpiUpdate}
              enumerationType="NPI-1"
              placeholder="NPI Number"
              component={NpiField}
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Col xs={12} sm={6}>
            <Field
              name="provider.firstName"
              label="First Name*"
              maxLength={64}
              component={TextField}
              disabled={physicianExists}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Field
              name="provider.middleName"
              label="Middle Name"
              maxLength={64}
              component={TextField}
              disabled={physicianExists}
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Col xs={12} sm={6}>
            <Field
              name="provider.lastName"
              label="Last Name*"
              maxLength={64}
              component={TextField}
              disabled={physicianExists}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Field
              label="DEA Number"
              placeholder="AB1234567"
              name="provider.dea"
              component={TextField}
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs={12} sm={6}>
            <Field
              label="Specialty"
              name="provider.specialty"
              component={TextField}
              disabled={physicianExists}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Field
              label="Email (For Site Access)"
              name="provider.email"
              placeholder="Email"
              component={EmailVerifyField}
              maxLength={64}
              verifyUrl="/mybv360/api/verify-user/contact/"
              validate={value => {
                return this.props.status.provider.email;
              }}
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Col md={4}>
            {error && (
              <div className="col-md-12 has-error">
                <p className="pull-left">
                  <i className="fa fa-exclamation-triangle fa-fw has-error" />
                  {error}
                </p>
              </div>
            )}
          </Col>
          <Col md={8}>
            <div className="float-end">
              <Button
                disabled={loading}
                onClick={this.props.closeModal}
                variant="secondary"
                className="btn--fw"
              >
                Cancel
              </Button>{' '}
              <DemoAwareButton
                demoAlert="This will add a physician"
                disabled={loading || !isValid}
                className="btn btn-primary btn--fw"
                onClick={e => {
                  this.addPhysician();
                }}
              >
                {loading ? <i className="fa fa-fw fa-lg fa-circle-notch fa-spin" /> : 'Add'}
              </DemoAwareButton>
            </div>
          </Col>
        </Row>
      </form>
    );
  }
}

export default AddPhysicianForm;
