import { hot } from 'react-hot-loader';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as UserActions from './redux/actions/user';
import * as AppActions from './redux/reducers/app';
import Spinner from 'shared/components/spinner/spinner';
import Routes from './routes';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import config from 'bv360/config';

class AppWrapper extends Component {
  constructor(props) {
    super(props);
    this.props.actions.enableRouting();
  }

  componentDidUpdate({
    rehydrated: rehydratedPrev,
    routerStarted: routerStartedPrev,
    ssoComplete: ssoCompletePrev
  }) {
    const { rehydrated, routerStarted } = this.props;
    if (
      rehydrated &&
      routerStarted &&
      (rehydrated !== rehydratedPrev || routerStarted !== routerStartedPrev)
    ) {
      this.props.actions.getCurrentUser(false);
    }
  }

  render() {
    const {
      persistor,
      rehydrated,
      routerStarted,
      store,
      history,
      loadingDemo,
      ssoComplete
    } = this.props;

    if (!rehydrated || !routerStarted || !ssoComplete || loadingDemo) {
      return <Spinner />;
    }

    return (
      <Provider store={store} key="provider">
        <PersistGate persistor={persistor} loading={<Spinner />}>
          <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKeyV3}>
            <Routes history={history} store={store} />
          </GoogleReCaptchaProvider>
        </PersistGate>
      </Provider>
    );
  }
}

AppWrapper.propTypes = {
  actions: PropTypes.object.isRequired,
  rehydrated: PropTypes.bool.isRequired,
  routerStarted: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  persistor: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  user: PropTypes.object,
  ssoComplete: PropTypes.object
};

AppWrapper = connect(
  state => ({
    ...state.app,
    loadingDemo: state.user.loadingDemo,
    ssoComplete: state.user.ssoComplete
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(UserActions, dispatch),
      ...bindActionCreators(AppActions, dispatch)
    }
  })
)(AppWrapper);

export default hot(module)(AppWrapper);
