import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import 'react-json-pretty/themes/monikai.css';
import ReactTable from 'react-table-6';
import './styles.css';
import DeleteModal from './modals/deleteModal';
import EditUserModal from './modals/editUserModal';
import AddUserModal from './modals/addUserModal';
import UserInfoModal from './modals/userInfoModal';
import AddPhysicianModal from './modals/add-physician/';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from 'bv360/redux/actions/user';
import isEmpty from 'lodash.isempty';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'shared/components/spinner/spinner';

class PracticeUserManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      deleteModalOpen: false,
      editUserModalopen: false,
      addPhysicianModalopen: false,
      userInfoModalOpen: false,
      selectedUser: {}
    };
  }

  columns = [
    {
      Header: 'First Name',
      id: 'firstName',
      accessor: 'user.first_name',
      minWidth: 30
    },
    {
      Header: 'Last Name',
      id: 'lastName',
      accessor: 'user.last_name',
      minWidth: 50
    },
    {
      Header: 'User Name',
      id: 'userName',
      accessor: 'user.username',
      minWidth: 70
    },
    {
      Header: 'Role(s)',
      id: 'role',
      accessor: 'compiled_roles',
      minWidth: 60
    },
    {
      Header: 'Delete',
      id: 'delete',
      minWidth: 20,
      Cell: row => {
        return row.original.user && this.props.user.username !== row.original.user.username ? (
          <button onClick={() => this.deleteModalToggle(row.original)} className="btn btn-primary">
            <i className="fa fa-trash" />
          </button>
        ) : null;
      }
    },
    {
      Header: 'Edit',
      id: 'edit',
      minWidth: 20,
      Cell: row => {
        return (
          <button
            // row.original is the selectedUser
            onClick={() => this.editUserModalToggle(row.original)}
            className="btn btn-primary"
          >
            <i className="fa fa-edit" />
          </button>
        );
      }
    }
  ];
  re = /^[0-9]*$/;
  isAllNumbers(val) {
    return this.re.test(val);
  }

  componentDidMount() {
    const isAdmin = this.props.user?.is_superuser || false;
    const isCustomerService = this.props.user?.groups?.includes('BV360 Customer Service') || false;
    const isAdminOrCS = isAdmin || isCustomerService;

    if(!isAdminOrCS) {
      this.props.actions.getPractices({}, false);
    }

    // add the new view modal if the user is a superuser on mount
    if (this.props.user?.is_superuser || this.props.user?.groups.includes('BV360 Customer Service'))
      this.columns.push({
        Header: 'CoPilot',
        id: 'CoPilot',
        minWidth: 20,
        Cell: row => {
          return (
            <button
              // row.original is the selectedUser
              onClick={() => this.userInfoModalToggle(row.original)}
              className="btn btn-primary"
            >
              <i className="fa fa-search" />
            </button>
          );
        }
      });
  }

  editUserModalToggle = (selectedUser = this.state.selectedUser) => {
    this.props.actions.clearCopilotDetails();
    this.setState({
      editUserModalOpen: !this.state.editUserModalOpen,
      selectedUser: selectedUser
    });
  };

  userInfoModalToggle = (selectedUser = this.state.selectedUser) => {
    this.state.userInfoModalOpen
      ? this.props.actions.clearCopilotDetails()
      : this.props.actions.getUserCopilotDetails(selectedUser);
    this.setState({
      userInfoModalOpen: !this.state.userInfoModalOpen,
      selectedUser: selectedUser
    });
  };

  addPhysicianModalToggle = () => {
    this.setState({
      addPhysicianModalopen: !this.state.addPhysicianModalopen
    });
  };

  deleteModalToggle = (selectedUser = this.state.selectedUser) => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      selectedUser: selectedUser
    });
  };

  deleteHandler = () => {
    this.props.actions.deactivateUser(this.state.selectedUser.id);
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen
    });
  };

  render() {
    if (this.props.loading) return <Spinner />;

    const isSystemsAdmin = this.props.user?.is_superuser || false;
    const isBV360CustomerService =
      this.props.user?.groups?.includes('BV360 Customer Service') || false;

    // add the new view column only if the user is a superuser

    if (this.props.practices.length === 0 && this.props.loading === false) {
      if (isSystemsAdmin || isBV360CustomerService) {
        return (
          <Container style={{ paddingTop: '120px', paddingBottom: '8rem' }} fluid={false}>
            <h1>
              {' '}
              The current user is not associated with any practice. Please first search for and
              select a practice.{' '}
            </h1>{' '}
          </Container>
        );
      } else {
        return (
          <Container style={{ paddingTop: '120px', paddingBottom: '8rem' }} fluid={false}>
            <h1> The current user is not associated with any practice.</h1>{' '}
          </Container>
        );
      }
    } else if (
      isEmpty(this.props.practice_users[0]) &&
      !isSystemsAdmin &&
      !isBV360CustomerService &&
      this.props.loading === false
    ) {
      return (
        <Container style={{ paddingTop: '120px', paddingBottom: '8rem' }} fluid={false}>
          <h1> The current user is not the administrator of any practice.</h1>{' '}
        </Container>
      );
    }
    // check if the first entry in this.props.practice_users is an empty object
    return (
      <Container style={{ paddingTop: '120px', paddingBottom: '8rem' }} fluid={false}>
        <Card>
          <Card.Body>
            <div style={{ fontSize: '20px' }}>
              Practice:{' '}
              {this.props.practices &&
              this.props.practices.length &&
              this.props.practices[0].name_edited
                ? this.props.practices[0].name_edited
                : this.props.practices[0].name}{' '}
            </div>
            <div style={{ fontSize: '13px' }}>
              *Blue Highlight Indicates Not Yet Invited to MyBV360{' '}
            </div>
          </Card.Body>
        </Card>
        <Row>
          <Col>
            <Alert variant="danger" show={!!this.props.error}>
              <i className="fa fa-fw fa-exclamation-triangle" /> {this.props.error}
            </Alert>
          </Col>
        </Row>
        <ReactTable
          data={
            isEmpty(this.props.practice_users[0])
              ? this.props.practice_users.slice(1)
              : this.props.practice_users
          }
          //data={this.compiled_users}
          minRows={10}
          columns={this.columns}
          className="-striped -highlight"
          showPagination={true}
          getTdProps={() => ({
            style: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }
          })}
          getTrProps={(state, rowInfo) => {
            if (rowInfo?.row) {
              return {
                style: {
                  background: this.isAllNumbers(rowInfo.row.userName) ? '#d4e4ff' : null
                }
              };
            } else {
              return {};
            }
          }}
        />
        <Button
          type="button"
          style={{ position: 'relative', top: '20px', left: '45%' }}
          onClick={() => this.props.actions.addUserModalToggle()}
        >
          Add Staff
        </Button>
        {/* add provider only available to practice admins in the practice
            practice_users[0] is reserved for the current user, this allows comparison to the
            primary 'user' in redux */}{' '}
        <Button
          type="button"
          style={{ position: 'relative', top: '20px', left: '45%' }}
          onClick={() => this.addPhysicianModalToggle()}
        >
          Add Provider
        </Button>
        <DeleteModal
          show={this.state.deleteModalOpen}
          onHide={this.deleteModalToggle}
          deleteHandler={this.deleteHandler}
        />
        <EditUserModal
          show={this.state.editUserModalOpen}
          isAdmin={isSystemsAdmin}
          onHide={this.editUserModalToggle}
          selectedUser={this.state.selectedUser}
          currentUserUsername={this.props.user.username}
          loading={this.props.loading}
          loadingCoPilot={this.props.loadingCoPilot}
          copilotDetails={this.props.copilot_user_details}
          copilotDetailsError={this.props.copilot_user_details_error}
        />
        <AddUserModal
          show={this.props.addUserModalOpen}
          onHide={this.props.actions.addUserModalToggle}
          currentUserUsername={this.props.user.username}
          requireFax={this.props.requireFax}
          practices={this.props.practices}
          loading={this.props.loading}
          error={this.props.error}
        />
        <AddPhysicianModal
          show={this.state.addPhysicianModalopen}
          onHide={this.addPhysicianModalToggle}
          error={null}
          loading={false}
          practices={this.props.practices}
          getpracticeusers={this.props.actions.getPracticeUsers}
        />
        <UserInfoModal
          show={this.state.userInfoModalOpen}
          isAdmin={isSystemsAdmin}
          onHide={this.userInfoModalToggle}
          selectedUser={this.state.selectedUser}
          currentUserUsername={this.props.user.username}
          loading={this.props.loading}
          loadingCoPilot={this.props.loadingCoPilot}
          copilotDetails={this.props.copilot_user_details}
          copilotDetailsError={this.props.copilot_user_details_error}
        />
      </Container>
    );
  }
}

export default connect(
  state => ({
    addUserModalOpen: state.user.addUserModalOpen,
    requireFax: state.user.requireFax,
    user: state.user.user,
    loading: state.user.loading,
    loadingCoPilot: state.user.loadingCoPilot,
    practice_users: state.user.practice_users,
    error: state.user.error,
    practices: state.user.practices,
    copilot_user_details: state.user.copilot_user_details,
    copilot_user_details_error: state.user.copilot_user_details_error
    // ssoComplete: state.user.ssoComplete
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(UserActions, dispatch)
    }
  })
)(PracticeUserManagement);
