import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ConfirmationModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Thank You!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{props.requestType === 'item' ? 
              'Are you sure you would like to request selected samples?' : 'Are you sure you would like to request selected documents?'}
        </h5>
      </Modal.Body>
      <Modal.Footer>

        <Button onClick = {() => {
            props.sendEmail(props.offices,props.contacts)
            props.onHide()
            props.successModal()
            } }>
        Yes
        </Button> 
        
        <Button>No</Button>
      </Modal.Footer>
    </Modal>
  );
}