import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from 'bv360/redux/actions/user';
import TeamMember from './TeamMember';
import { Link } from 'react-router-dom';
import { URLS } from 'bv360/utils/urls';
import * as RX_URLS from 'exogen-rx/utils/urls';
import Container from 'react-bootstrap/Container';
import VirtualizedSelect from 'react-virtualized-select';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as DashboardActions from './reducer';
import config from 'bv360/config';
import Modal from 'react-bootstrap/Modal';

function IRSModal(props) {
  return (
    <Modal show={props.show} onHide={props.onHide} size="lg" centered>
      <Modal.Header closeButton>
        <h4>
          What is BV360 <strong>Reimbursement Solution</strong>?
        </h4>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{ lineHeight: '1.6' }}>
          {' '}
          The BV360 Reimbursement Solution is a free, full-service tool designed to streamline
          access to the Bioventus portfolio of HA therapies: DUROLANE, GELSYN-3, and SUPARTZ FX.
          With BV360, you can expect benefit determination within 24 hours, and BV360 is your go-to
          resource for insurance coverage, out of pocket costs, prior authorization support and
          Specialty Pharmacy coordination.
        </h5>
      </Modal.Body>
    </Modal>
  );
}

function IOPModal(props) {
  return (
    <Modal show={props.show} onHide={props.onHide} size="lg" centered>
      <Modal.Header closeButton>
        <h4>
          What is <strong>Injectables Ordering and Payment</strong>?
        </h4>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{ lineHeight: '1.6' }}>
          {' '}
          The Injectables Orders and Payment system allows you to place orders 24/7 for DUROLANE,
          GELSYN-3, and SUPARTZ FX. View your ordering history, invoices, credit limits, and make
          payments on open invoices.
        </h5>
      </Modal.Body>
    </Modal>
  );
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      officevalue: { label: 'All Offices', value: 'All Offices' },
      showIRSModal: false,
      showIOPModal: false,
      customerCareContacts: [
        {
          name: 'Customer Care',
          prefix: '',
          email: 'customerserviceusa@bioventus.com',
          phone: '+18008364080',
          fax: '+18668327284',
          photo: '/contact_photos/customerserviceusabioventusglobal.com.jpg'
        },
        {
          name: 'BV360 Reimbursement Solution',
          prefix: 'Benefits Investigation',
          email: 'bv360-support@cmcopilot.com',
          phone: '+18336928360',
          fax: null,
          photo: '/contact_photos/bv360-supportcmcopilot.com.jpg'
        }
      ]
    };
  }

  componentDidMount() {
    this.props.actions.getPractices({}, false);
    this.props.actions.getSystemConfig();
  }

  removeDuplicates(myArr, prop) {
    if (myArr === null || myArr === undefined) return [];
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  openNewTab = system => {
    if (this.prop?.user?.is_demo) {
    } else {
      window.open(config.copilotOkaRedirect, '_blank');
    }
  };

  handleCorevistLink = () => {
    if (this.props?.corevistIsDown) {
      window.location.replace(URLS.COREVIST_DOWN);
      return false;
    }
    if (!this.props.user.is_superuser && this.props?.user?.is_rep && !this.props?.user?.is_demo) {
      alert(
        'Customers will be able to access the BV360 Injectables Reimbursement Solution. Sales representatives should access BV360 territory data through Okta.'
      );
    } else if (this.props.user.corevist_user) {
      if (this.props?.user?.is_demo) {
        alert(
          'Demo Mode: Customers will be able to access the Injectables Orders and Payment system.'
        );
      } else {
        window.open(config.corevistOkaRedirect, '_blank');
      }
    } else if (this.props.user.corevist_in_progress) {
      this.props.actions.corevistAccountStatus();
    } else {
      this.props.actions.toggleCorevistModal(!this.props.showCorevistRegisterModal);
    }
  };

  handleCopilotLink = () => {
    if (!this.props.user.is_superuser && this.props?.user?.is_rep && !this.props?.user?.is_demo) {
      alert(
        'Customers will be able to access the BV360 Injectables Reimbursement Solution. Sales representatives should access BV360 territory data through Okta.'
      );
    } else if (this.props.user.copilot_user) {
      if (!this.props.user?.copilot_warning_accepted && this.props.user?.copilot_quick_enroll) {
        this.props.actions.toggleWarningModal();
      } else {
        const url = this.props?.user?.is_demo ? config.copilotDemoUrl : config.copilotOkaRedirect;
        window.open(url, '_blank');
      }
    } else {
      this.props.actions.toggleCopilotModal(
        !this.props.showCopilotModal,
        this.props.user.practice_has_copilot_user
      );
    }
  };

  handleIRSMoreInfo = event => {
    event?.stopPropagation();
    this.setState({
      showIRSModal: !this.state.showIRSModal
    });
  };

  handleIOPMoreInfo = (event = null) => {
    event?.stopPropagation();
    this.setState({
      showIOPModal: !this.state.showIOPModal
    });
  };

  handleExogenRxLink = () => {
    window.open('/mybv360/app/exogen-rx', '_blank');
  };

  changeOfficeHandler = (officevalue = null) => {
    this.setState({
      officevalue: officevalue
    });
  };

  OptionRender = ({ key, option, selectValue, style }) => {
    return (
      <div
        key={key}
        onClick={() => selectValue(option)}
        style={{ ...style, overflow: 'hidden', cursor: 'pointer' }}
      >
        {option.value}
      </div>
    );
  };

  render() {
    const { practices } = this.props;

    let contactList = [];
    let offices = [];

    let memberIndex;

    offices = practices
      .reduce((acc, curr) => {
        return acc.concat(curr.offices);
      }, [])
      .filter(val => val != null); // this filters out both null and undefined;

    const officeOptions = offices
      .map(val => {
        let obj = {};
        val.address1_edited ? (obj.label = val.address1_edited) : (obj.label = val.address1);
        val.address1_edited ? (obj.value = val.address1_edited) : (obj.value = val.address1);
        return obj;
      })
      .concat([{ label: 'All Offices', value: 'All Offices' }]);

    contactList = offices
      .filter(val => val.contacts != null) // this filters out both null and undefined
      .reduce((acc, curr) => {
        return acc.concat(curr.contacts);
      }, []);

    if (!offices.length || !contactList.length) {
      // this should be a last resort if nothing at all is returned from database;
      contactList = this.state.customerCareContacts;
    } else if (this.state.officevalue.label === 'All Offices') {
      contactList = this.removeDuplicates(contactList, 'id');
    } else {
      contactList = this.removeDuplicates(
        offices
          .filter(val => {
            if (val.address1_edited) {
              return val.address1_edited === this.state.officevalue.label;
            } else {
              return val.address1 === this.state.officevalue.label;
            }
          })
          .reduce((acc, curr) => acc.concat(curr.contacts), []),
        'id'
      );
    }

    if (
      !(
        contactList.map(val => val.name).includes('Customer Care') ||
        contactList.map(val => val.name).includes('Customer Service')
      )
    )
      contactList.push(this.state.customerCareContacts[0]);
    if (!contactList.map(val => val.name).includes('BV360 Reimbursement Solution'))
      contactList.push(this.state.customerCareContacts[1]);

    memberIndex = contactList.findIndex(val => val.name === 'Customer Care');
    if (memberIndex !== -1) contactList.push(contactList.splice(memberIndex, 1)[0]);
    memberIndex = contactList.findIndex(val => val.name === 'Customer Service');
    if (memberIndex !== -1) contactList.push(contactList.splice(memberIndex, 1)[0]);
    memberIndex = contactList.findIndex(val => val.name === 'BV360 Reimbursement Solution');
    if (memberIndex !== -1) contactList.push(contactList.splice(memberIndex, 1)[0]);

    return (
      <Container style={{ padding: '0rem' }} fluid={true}>
        <section
          className="Background LandingPage"
          style={{ backgroundImage: 'url(/static/images/texture1.jpg)' }}
        >
          <div className="Inner">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="BoxContents"
                      style={{ cursor: 'pointer' }}
                      onClick={this.handleCopilotLink}
                    >
                      <img
                        src="/static/images/icon_reimbursement.png"
                        alt="BV360 Reimbursement Solution"
                      />

                      <div className="Name TileName">
                        <a>BV360 Reimbursement Solution</a>
                        <a>
                          <h4>Benefits Investigations</h4>
                        </a>
                      </div>

                      <div className="SubLink">
                        <a>
                          DUROLANE<span></span>
                        </a>
                        |
                        <a>
                          GELSYN-3<span></span>
                        </a>
                        |
                        <a>
                          SUPARTZ FX<span></span>
                        </a>
                      </div>
                      <div className="SubLink">
                        <a
                          onClick={this.handleIRSMoreInfo}
                          style={{ fontSize: '14px', textTransform: 'none' }}
                        >
                          Read More (click here)<span></span>
                        </a>
                      </div>
                    </div>

                    {/* </a> */}
                  </div>

                  <div className="col-md-6">
                    {config.features.exogenRx && (

                        <div
                          className="BoxContents"
                          style={{ cursor: 'pointer' }}
                          onClick={e => {window.open("/exogen-rx/app", '_blank');}}
                        >
                          <img src="/static/images/icon_exogen.png" alt="EXOGEN Rx" />

                          <div className="Name TileName">
                            <span>EXOGEN Rx</span>
                          </div>
                          <div className="SubLink">
                            <span>
                              Bone Stimulator<span></span>
                            </span>
                          </div>
                        </div>

                    )}
                    {!config.features.exogenRx && (
                      <div className="BoxContents" style={{ cursor: 'pointer' }} onClick={e => {}}>
                        <img src="/static/images/icon_exogen.png" alt="EXOGEN Rx" />

                        <div className="Name TileName">
                          <a>EXOGEN Rx</a>
                        </div>
                        <div className="SubLink">
                          <a>
                            Bone Stimulator<span></span>
                          </a>
                        </div>
                        <div className="SubLink">
                          <a>Coming Soon!</a>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-md-6">
                    <div
                      className="BoxContents"
                      style={{ cursor: 'pointer' }}
                      onClick={this.handleCorevistLink}
                    >
                      <img
                        src="/static/images/syringe.png"
                        alt="HA Ordering"
                        style={{ height: '120px' }}
                      />

                      <div className="Name TileName">
                        <a>Injectable Online Orders and Payment</a>
                        <a>
                          <h4>Place Orders / View Invoices / Make Payments</h4>
                        </a>
                      </div>

                      <div className="SubLink">
                        <a>
                          DUROLANE<span></span>
                        </a>
                        |
                        <a>
                          GELSYN-3<span></span>
                        </a>
                        |
                        <a>
                          SUPARTZ FX<span></span>
                        </a>
                      </div>
                      <div className="SubLink">
                        <a
                          onClick={this.handleIOPMoreInfo}
                          style={{ fontSize: '14px', textTransform: 'none' }}
                        >
                          Read More (click here)<span></span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <Link to={URLS.ACCOUNT_PROFILE} style={{ whiteSpace: 'nowrap' }}>
                      <div className="BoxContents">
                        <img src="/static/images/icon_bv.png" alt="myBV360" />

                        <div className="Name TileName">Account Details</div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="BoxContents Team">
                  {contactList ? (
                    <React.Fragment>
                      <div className="Name">
                        <Row style={{ paddingBottom: '0.2em' }}>
                          <Col xs={6} sm={4}>
                            MyBV360 <strong>Team</strong>
                          </Col>
                          <Col
                            xs={18}
                            sm={8}
                            style={{
                              textAlign: 'right',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {(this.props.practices.length &&
                              (this.props.practices[0].name_edited
                                ? this.props.practices[0].name_edited
                                : this.props.practices[0].name)) ||
                              'No Practice Found'}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} sm={3}>
                            {' '}
                          </Col>
                          <Col xs={18} sm={9} className="OfficeDropdown">
                            <VirtualizedSelect
                              value={this.state.officevalue}
                              options={officeOptions}
                              onChange={val => this.changeOfficeHandler(val)}
                              clearable={false}
                              searchable={false}
                              // style={{ overflow: 'hidden'}}
                              // simpleValue
                              optionRenderer={this.OptionRender}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="OverflowContents">
                        {!this.props.globalLoading &&
                          contactList.map(val => (
                            <TeamMember
                              fullName={val.name}
                              title={val.prefix}
                              products={val.products}
                              email={val.email}
                              phone={val.phone}
                              fax={val.fax}
                              key={val.name}
                              photo={val.photo}
                            />
                          ))}
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <IRSModal show={this.state.showIRSModal} onHide={this.handleIRSMoreInfo} />
          <IOPModal show={this.state.showIOPModal} onHide={this.handleIOPMoreInfo} />
        </section>
        {/* should probably start hiding this complexity or simplifying it */}
      </Container>
    );
  }
}

export default connect(
  state => ({
    user: state.user.user,
    practices: state.user.practices,
    globalLoading: state.user.loading,
    ...state.dashboard,
    corevistIsDown: state.dashboard?.systemConfig?.corevistIsDown
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(UserActions, dispatch),
      ...bindActionCreators(DashboardActions, dispatch)
    }
  })
)(Dashboard);
