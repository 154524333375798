
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { URLS } from 'bv360/utils/urls';


export default function SuccessModal(props) {

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Success</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{props.requestType === 'item' ? 'The following sample: ' : 'The following documents: '} </h5>
        <ul>
          {props.modalSamples.map((val, index) => (
            <li key={index}>
              {' '}
              {props.requestType === 'item' ? 
                val.quantity + ' ' + val.product_name : val.quantity + ' ' + val.document_name
              }
            </li>
          ))}
        </ul>
        {props.modalOffice ? (
          <h5>will be delivered by your sales representative to {props.modalOffice}.</h5>
        ) : (
          <h5>will be delivered by your sales representative to {props.allOffices[0]}. </h5>
        )}
      </Modal.Body>
      <Modal.Footer>
        {/*<Button onClick={props.onHide}>Close</Button>*/}
        <Button href={URLS.BASE}>Return to Homepage</Button>
      </Modal.Footer>
    </Modal>
  );
}