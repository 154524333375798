import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DemoAwareButton from 'bv360/components/demo/button';

export default class deleteModal extends React.PureComponent {
  render() {
    return (
      <Modal
        // {...props}
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete this user? </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button // wrap in evt function so that the onhide function
            // isn't invoked immediately
            onClick={evt => this.props.onHide(null)}
            variant="secondary"
            className="btn--fw"
          >
            Cancel
          </Button>
          <DemoAwareButton
            demoAlert="This would delete a user"
            type="button"
            onClick={this.props.deleteHandler}
          >
            Delete
          </DemoAwareButton>
        </Modal.Footer>
      </Modal>
    );
  }
}
