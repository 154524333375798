import React from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';

export default function DemoAwareButton({ children, demoAlert = 'Demo Mode', ...rest }) {
  const isDemo = useSelector(state => state?.user?.user?.is_demo);

  const demoClick = evt => {
    evt.preventDefault();
    alert(`Demo Mode: ${demoAlert}`);
  };

  return (
    <Button {...rest} onClick={isDemo ? demoClick : rest.onClick}>
      {children}
    </Button>
  );
}
